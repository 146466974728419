import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { FC, useState, useEffect } from 'react';
import UserClasification from '../components/UserClasification';
import AvailableCenters from '../components/AvailableCenters';
import VariableSettings from '../components/VariableSettings';
import { useDispatch, useSelector } from 'react-redux';
import * as customerSelectors from '../../domain/store/customers/selectors';
import * as customerActions from '../../domain/store/customers';
import * as centerActions from '../../domain/store/centers';
import { Center } from '../../domain/entities/Center';
import { useForm } from 'react-hook-form';
import { UserAccess } from '../../shared/enums/user-access.enum';
import http from '../../infrastructure/api/http';
import UserForm from '../components/UserForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Customer } from '../../domain/entities/Customer';
import ICON_SAVE from '../../shared/assets/menu/icon_save.png';

type CenterVariable = {
  centerId: number;
  twoAr: boolean;
  variables: Array<{
    variableId: number;
    settings: {
      minPoint: number;
      maxPoint: number;
      multiplier: number;
      adjust: number;
      type: string | null;
    };
  }>;
};

type FormData = {
  username: string;
  names: string;
  email: string;
  password: string;
  phone: string;
  access: UserAccess;
  centers: CenterVariable[];
	useDashboard: boolean;
	isElectricOnly: boolean;
};

const AdminUser: FC = () => {
  const { customerId, userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [httpErrors, setHttpErrors] = useState<string | null>(null);
  const [selectedSettingCenter, setSelectedSettingCenter] =
    useState<CenterVariable | null>(null);
  const selectedCustomer = useSelector(customerSelectors.selectSelected);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    if (data.centers.length === 0) {
      alert('Por favor selecciona al menos 1 centro');
      return;
    }

    if (userId !== undefined) {
      try {
        await http.put(
          `/users/${userId}`,
          { ...data, customerId: customerId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        alert(`El usuario se ha actualizado correctamente`);
        navigate(-1);
      } catch (error: any) {
        setHttpErrors(error.response.data.message || error.message);
      }
    } else {
      try {
        await http.post(
          '/users',
          { ...data, customerId: customerId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        alert(`El usuario se ha creado correctamente`);
        navigate(-1);
      } catch (error: any) {
        setHttpErrors(error.response.data.message || error.message);
      }
    }
  };

  useEffect(() => {
    if (!selectedCustomer && customerId) {
      (async () => {
        try {
          const customer = await http.get<Customer>(`/customers/${customerId}`);
          dispatch(customerActions.setSelected(customer.data));
        } catch (error: any) {
          console.error(error);
          setHttpErrors(error.response.data.message || error.message);
        }
      })();
    }
    if (selectedCustomer)
      dispatch(centerActions.loadCentersByCustomerId(selectedCustomer.id));
  }, [selectedCustomer, customerId, dispatch]);

  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await http.get(`/users/${userId}`);
          setValue('username', response.data.username);
          setValue('access', response.data.access);
          setValue('names', response.data.name);
          setValue('email', response.data.email);
          setValue('phone', response.data.phone);
          setValue('useDashboard', response.data.useDashboard);
          setValue('isElectricOnly', response.data.isElectricOnly);
          setValue(
            'centers',
            response.data.centers.map((center: any) => {
              return {
                centerId: center.id,
                twoAr: center.twoAr,
                variables: [
                  {
                    variableId: 1,
                    settings: {
                      adjust: 0,
                      maxPoint: 4.5,
                      minPoint: 0.5,
                      multiplier: 1,
                      type: null,
                    },
                    data: [],
                  },
                  {
                    variableId: 2,
                    settings: {
                      adjust: 0,
                      maxPoint: 0,
                      minPoint: 0,
                      multiplier: 0,
                      type: null,
                    },
                    data: [
                      'va',
                      'vb',
                      'vc',
                      'ia',
                      'ib',
                      'ic',
                      'fa',
                      'fb',
                      'fc',
                    ].map((type, index) => {
                      let max = 4.5;
                      let min = 0.5;

                      if (index >= 0 && index <= 2) {
                        max = 245;
                        min = 0;
                      }

                      if (index >= 3 && index <= 5) {
                        max = 100;
                        min = 0;
                      }

                      return {
                        adjust: 0,
                        type: type,
                        maxPoint: max,
                        minPoint: min,
                        multiplier: 1,
                      };
                    }),
                  },
                ],
              };
            })
          );
        } catch (error: any) {
          console.error(error);
          setHttpErrors(error.response.data.message || error.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [userId, setValue]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {httpErrors && (
        <Box component="div" sx={{ p: 2, width: '100%' }}>
          <Alert severity="error">{httpErrors}</Alert>
        </Box>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="div" sx={{ p: 2, width: '100%', marginBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Typography variant="h5" flex={1}>
              Usuario
            </Typography>
            <IconButton
              type="submit"
              aria-label="save"
              size="small"
              sx={{ p: 0 }}
            >
              <img src={ICON_SAVE} alt="Guardar" width={48} />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 5 }}>
            <UserForm
              control={control}
              userId={userId}
              register={register}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ height: 10 }}></Grid>
          <Grid item xs={12} sm={4}>
            <UserClasification
              control={control}
              register={register}
              name="access"
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ borderLeft: '1px solid #ddd' }}>
            <AvailableCenters
              selectedCenters={
                getValues('centers')
                  ? getValues('centers').map((center) => center.centerId)
                  : []
              }
              onSettingCenter={(center: Center | null) => {
                if (center) {
                  const centerSearch = getValues('centers').find(
                    (c) => c.centerId === center?.id
                  );

                  if (centerSearch) setSelectedSettingCenter(centerSearch);
                } else {
                  setSelectedSettingCenter(null);
                }
              }}
              onSelectedCenters={(centers) => {
                setSelectedSettingCenter(null);
                setValue(
                  'centers',
                  centers.map((center) => {
                    return {
                      centerId: center,
                      twoAr: false,
                      variables: [
                        {
                          variableId: 1,
                          settings: {
                            adjust: 0,
                            type: null,
                            maxPoint: 4.5,
                            minPoint: 0.5,
                            multiplier: 1,
                          },
                          data: [],
                        },
                        {
                          variableId: 2,
                          settings: {
                            adjust: 0,
                            type: null,
                            maxPoint: 4.5,
                            minPoint: 0.5,
                            multiplier: 1,
                          },
                          data: [
                            'va',
                            'vb',
                            'vc',
                            'ia',
                            'ib',
                            'ic',
                            'fa',
                            'fb',
                            'fc',
                            'd',
                            'l',
                            'f',
                            'a',
                          ].map((type) => ({
                            adjust: 0,
                            type: type,
                            maxPoint: 4.5,
                            minPoint: 0.5,
                            multiplier: 1,
                          })),
                        },
                      ],
                    };
                  })
                );
              }}
              {...register('centers', { value: [] })}
            />
          </Grid>
          {selectedSettingCenter && (
            <Grid item xs={12} sm={4} sx={{ borderLeft: '1px solid #ddd' }}>
              <VariableSettings
                userId={parseInt(userId || '-1')}
                center={selectedSettingCenter}
                onChange={async (data) => {
                  setIsLoading(true);
                  try {
                    const centerIdx = getValues('centers').findIndex(
                      (c) => c.centerId === selectedSettingCenter.centerId
                    );
                    if (centerIdx !== -1) {
                      const selectedCenters = [...getValues('centers')];
                      await http.put(
                        `/centers/${selectedCenters[centerIdx].centerId}/settings/${userId}`,
                        {
                          variables: data.map((d) => ({
                            variableId: d.variableId,
                            settings: d.settings,
                            red: d.red || [],
                          })),
                        }
                      );
                      selectedCenters[centerIdx].variables = data;
                      setValue('centers', selectedCenters);
                    }
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminUser;
