import { takeEvery, put } from 'redux-saga/effects';
import * as customerActions from './index';
import customerService from '../../../application/services/CustomerService';
import { Customer } from '../../entities/Customer';

function* handleLoadCustomers(data: any) {
  const { payload } = data;
  try {
    yield put(customerActions.setLoading(true));
    const response: { customers: Customer[]; total: number } =
      yield customerService.loadCustomers(payload.page, payload.limit);
    yield put(customerActions.setCustomers(response));
  } catch (error: any) {
    yield put(customerActions.setError(error.message));
  } finally {
    yield put(customerActions.setLoading(false));
  }
}

export function* customerSaga() {
  yield takeEvery(customerActions.loadCustomers.type, handleLoadCustomers);
}
