import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const selectCenter = (state: RootState) => state.center;

export const selectLoading = createSelector(
  [selectCenter],
  (center) => center.loading
);

export const selectCenters = createSelector(
  [selectCenter],
  (center) => center.centers
);

export const selectError = createSelector(
  [selectCenter],
  (center) => center.error
);
