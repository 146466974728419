import * as centerActions from './index';
import { put, takeEvery } from 'redux-saga/effects';
import centerService from '../../../application/services/CenterService';
import { Center } from '../../entities/Center';

function* handleLoadCentersByCustomerId(data: any) {
  const { payload } = data;
  try {
    yield put(centerActions.setLoading(true));
    const response: Center[] = yield centerService.loadCentersByCustomerId(
      payload
    );
    yield put(centerActions.setCenters(response));
  } catch (error: any) {
    yield put(centerActions.setError(error.message));
  } finally {
    yield put(centerActions.setLoading(false));
  }
}

export function* centerSaga() {
  yield takeEvery(
    centerActions.loadCentersByCustomerId.type,
    handleLoadCentersByCustomerId
  );
}
