import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Center } from '../../entities/Center';

export interface CenterState {
  centers: Center[];
  loading: boolean;
  error: string | null;
}

const initialState: CenterState = {
  centers: [],
  loading: false,
  error: null,
};

const centerSlice = createSlice({
  name: 'center',
  initialState,
  reducers: {
    loadCentersByCustomerId(state, action: PayloadAction<number>) {},
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setCenters(state, action: PayloadAction<Center[]>) {
      state.centers = action.payload;
    },
  },
});

export const { loadCentersByCustomerId, setCenters, setError, setLoading } =
  centerSlice.actions;

export default centerSlice.reducer;
