import { useState, useEffect, FC } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Chart } from 'react-google-charts';
import http from '../../infrastructure/api/http';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type Props = {
  centerId: number;
  idx: number;
};

const CenterStatusHistory: FC<Props> = ({ centerId, idx }) => {
  const theme = useTheme();
  const [dataGraphic, setDataGraphic] = useState([
    ['Hora', 'IL1', 'IL2', 'IL3', 'VL1', 'VL2', 'VL3', 'FL1', 'FL2', 'FL3'],
    ['10/07 10', 10, 10, 10, 10, 10, 10, 10, 10, 10],
  ]);
  const [filterHour, setFilterHour] = useState<'1h' | '6h' | '24h'>('1h');
  const [collapsed, setCollapsed] = useState(true);

  const updateData = async () => {
    try {
      const response = await http.get(
        `/centers/${centerId}/status/${filterHour.replace('h', '')}`
      );
      if (response.data.length > 0) {
        setDataGraphic([
          [
            'Hora',
            'IL1',
            'IL2',
            'IL3',
            'VL1',
            'VL2',
            'VL3',
            'FL1',
            'FL2',
            'FL3',
          ],
          ...response.data.map((item: any) => [
            dayjs(item.createdAt).format('HH:mm'),
            item.cfa,
            item.cfb,
            item.cfc,
            item.vfa,
            item.vfb,
            item.vfc,
            item.ffa,
            item.ffb,
            item.ffc,
          ]),
        ]);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    updateData();
    const interval = setInterval(() => {
      updateData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId, filterHour]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="body1" fontWeight={600}>
            Variables eléctricas AR {idx}
          </Typography>
        }
        action={
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              disableElevation
              size="small"
            >
              <Button
                variant={filterHour === '1h' ? 'contained' : 'outlined'}
                onClick={() => setFilterHour('1h')}
              >
                1h
              </Button>
              <Button
                variant={filterHour === '6h' ? 'contained' : 'outlined'}
                onClick={() => setFilterHour('6h')}
              >
                6h
              </Button>
              <Button
                variant={filterHour === '24h' ? 'contained' : 'outlined'}
                onClick={() => setFilterHour('24h')}
              >
                24h
              </Button>
            </ButtonGroup>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {!collapsed ? <AddIcon /> : <RemoveIcon />}
            </Button>
          </Box>
        }
      />
      <CardContent sx={{ p: '10px', display: collapsed ? 'block' : 'none' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#009581' }} />
          </Grid>
          <Grid item xs={12}>
            <Chart
              chartType="LineChart"
              width="100%"
              height="250px"
              data={dataGraphic}
              options={{
                backgroundColor: 'transparent',
                chart: {
                  title: 'Enerpry',
                },
                hAxis: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                  minValue: 0,
                  viewWindow: {
                    min: 0,
                  },
                },
                vAxis: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                  minValue: 0,
                  viewWindow: {
                    min: 0,
                  },
                },
                legend: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
                annotations: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CenterStatusHistory;
