import { FC, useEffect } from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { esES } from '@mui/material/locale';
import { esES as datePickerES } from '@mui/x-date-pickers/locales';
import { esES as dataGridEs } from '@mui/x-data-grid/locales';

import { selectTheme } from '../../domain/store/ui/selectors';
import { setIsSmallScreen, setTheme } from '../../domain/store/ui';

const darkTheme = createTheme(
  {
    typography: {
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#009581',
      },
      secondary: {
        main: red.A700,
      },
      background: {
        default: '#212630',
      },
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          root: {
            backgroundColor: '#212630',
            borderColor: '#212630',
          },
          paper: {
            backgroundColor: '#212630',
            borderColor: '#212630',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#212630',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: '#212630',
          },
        },
      },
    },
  },
  esES,
  datePickerES,
  dataGridEs
);

type Props = {
  children: React.ReactNode;
};

const ThemeProvider: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('md'));

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      dispatch(setTheme(storedTheme as 'light' | 'dark'));
    } else {
      dispatch(setTheme(!prefersDarkMode ? 'light' : 'dark'));
    }
  }, [dispatch, prefersDarkMode]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    dispatch(setIsSmallScreen(isSmallScreen));
  }, [dispatch, isSmallScreen]);

  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
