import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UIState {
  theme: 'light' | 'dark';
  isSmallScreen: boolean;
  isDrawerOpen: boolean;
}

const initialState: UIState = {
  theme: (localStorage.getItem('theme') as 'light' | 'dark' | null) || 'dark',
  isSmallScreen: false,
  isDrawerOpen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<'light' | 'dark'>) {
      state.theme = action.payload;
    },
    setIsSmallScreen(state, action: PayloadAction<boolean>) {
      state.isSmallScreen = action.payload;
    },
    setIsDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { setTheme, setIsSmallScreen, setIsDrawerOpen } = uiSlice.actions;

export default uiSlice.reducer;
