import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as userSelectors from '../../domain/store/users/selectors';
import * as userActions from '../../domain/store/users';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UserAccess } from '../../shared/enums/user-access.enum';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';
import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_REMOVE from '../../shared/assets/menu/icon_remove.png';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import http from '../../infrastructure/api/http';
import { selectUser } from '../../domain/store/session/selectors';

const AdminCustomerUsers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const users = useSelector(userSelectors.selectUsers);
  const isLoading = useSelector(userSelectors.selectLoading);
  const error = useSelector(userSelectors.selectError);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (customerId)
      dispatch(userActions.loadUsers({ customerId: parseInt(customerId) }));
  }, [dispatch, customerId]);

  const getAccessString = (access: string): string => {
    if (access === UserAccess.CLI_ADMIN) return 'Gerente Cliente';
    if (access === UserAccess.SUPER_USER) return 'Superusuario';
    if (access === UserAccess.ENERPRY) return 'Operador Enerpry';
    if (access === UserAccess.CLI_CENTER) return 'Operador Cliente';
    return '-';
  };

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            flex={1}
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            Lista de usuarios
          </Typography>
          <Box
            component="div"
            width="100px"
            display="flex"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              aria-label="add"
              onClick={() => {
                navigate(`/admin/customers/${customerId}/users/add`);
              }}
              sx={{ p: 0 }}
            >
              <img src={ICON_PLUS} alt="Nuevo cliente" width={48} />
            </IconButton>
          </Box>
        </Grid>
        {error && (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center', padding: 15 }}
          >
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 5 }}>
          <TableContainer>
            <Table
              sx={{
                backgroundColor: 'transparent',
                border: 0,
                width: isSmallScreen ? '100%' : '60%',
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    'td, th': { border: 0 },
                  }}
                >
                  <TableCell>
                    <Typography fontWeight={'600'}>
                      <ChevronRightIcon /> Nombre de usuario
                    </Typography>
                  </TableCell>
                  {!isSmallScreen && (
                    <TableCell align="center">
                      <Typography fontWeight={'600'}>
                        Tipo de usuario
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Typography fontWeight={'600'}>Acción</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={2}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 15,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ padding: 1 }}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          sx={{
                            background:
                              'linear-gradient(135deg, rgba(5, 242, 219, 0.1), rgba(255, 255, 255, 0.1))',
                            color: '#fff',
                          }}
                          children={
                            <strong>{user.name[0].toUpperCase()}</strong>
                          }
                        />
                        <Box display={'flex'} flexDirection={'column'}>
                          {user.name}
                          {isSmallScreen && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {getAccessString(user.access)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    {!isSmallScreen && (
                      <TableCell align="center" sx={{ padding: 1 }}>
                        {getAccessString(user.access)}
                      </TableCell>
                    )}
                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          navigate(
                            `/admin/customers/${customerId}/users/${user.id}`
                          );
                        }}
                      >
                        <img
                          src={ICON_SETTINGS}
                          alt="Configuración"
                          width={14}
                        />
                      </IconButton>
                      {currentUser?.id !== user.id && (
                        <IconButton
                          aria-label="show"
                          onClick={async () => {
                            if (
                              window.confirm(
                                '¿Está seguro que desea eliminar este usuario?'
                              )
                            ) {
                              if (!customerId) return;
                              try {
                                await http.delete(`/users/${user.id}`);
                                dispatch(
                                  userActions.loadUsers({
                                    customerId: parseInt(customerId),
                                  })
                                );
                              } catch (error) {
                                alert(
                                  'Se ha producido un error al remover el usuario'
                                );
                              }
                            }
                          }}
                        >
                          <img src={ICON_REMOVE} alt="Eliminar" width={14} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminCustomerUsers;
