import { User } from '../../domain/entities/User';
import { UserRepository } from '../../domain/repositories/UserRepository';
import http from '../api/http';

export class RestApiUserRepository implements UserRepository {
  async signIn(
    username: string,
    password: string,
    remember: boolean
  ): Promise<{ token: string }> {
    try {
      const response = await http.post<{ token: string }>('/auth/login', {
        username,
        password,
        rememberMe: remember,
      });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async findById(id: number): Promise<User> {
    try {
      const response = await http.get<User>(`/users/${id}`);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async findByCustomerId(customerId: number): Promise<User[]> {
    try {
      const response = await http.get<User[]>(`/customers/${customerId}/users`);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
}
