import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../entities/User';
import { Customer } from '../../entities/Customer';

export interface SessionState {
  user: User | null;
  customer: Customer | null;
  loading: boolean;
  error: string | null;
}

const initialState: SessionState = {
  user: null,
  customer: null,
  loading: false,
  error: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setLogIn(
      state,
      action: PayloadAction<{
        username: string;
        password: string;
        remember: boolean;
      }>
    ) {},
    setUser(state, action: PayloadAction<User | null>) {
      state.error = null;
      state.user = action.payload;
    },
    validateSession(state) {},
    setCustomer(state, action: PayloadAction<Customer | null>) {
      state.customer = action.payload;
    },
    updatePicture(state, action: PayloadAction<{type: string, data: number[];} | string>) {
      if (state.user) state.user.picture = action.payload;
    },
  },
});

export const {
  setLoading,
  setLogIn,
  setError,
  setUser,
  validateSession,
  setCustomer,
  updatePicture,
} = sessionSlice.actions;

export default sessionSlice.reducer;
