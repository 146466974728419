import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { UserAccess } from '../../shared/enums/user-access.enum';
import { Controller } from 'react-hook-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type Props = {
  register: any;
  name: string;
  control: any;
};

const UserClasification: FC<Props> = ({ register, name, control }) => {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <Typography variant={'h6'}>
            <ChevronRightIcon /> Clasificación de usuario
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ height: 10 }}></Grid>
        <Grid item xs={12}>
          <Controller
            name={name}
            defaultValue={UserAccess.SUPER_USER}
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                sx={{ paddingLeft: 2, paddingTop: '15px' }}
              >
                <FormControlLabel
                  value={UserAccess.SUPER_USER}
                  control={
                    <Radio
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label={
                    <Typography variant={'body1'} sx={{ pl: '0.8cm' }}>
                      Super usuario
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={UserAccess.ENERPRY}
                  control={
                    <Radio
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  sx={{ mt: 1 }}
                  label={
                    <Typography variant={'body1'} sx={{ pl: '0.8cm' }}>
                      Enerpry
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={UserAccess.CLI_ADMIN}
                  control={
                    <Radio
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  sx={{ mt: 1 }}
                  label={
                    <Typography variant={'body1'} sx={{ pl: '0.8cm' }}>
                      Gerente
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={UserAccess.CLI_CENTER}
                  control={
                    <Radio
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  sx={{ mt: 1 }}
                  label={
                    <Typography variant={'body1'} sx={{ pl: '0.8cm' }}>
                      Operador
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserClasification;
