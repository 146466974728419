import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminCustomers from '../pages/AdminCustomers';
import AdminCustomerUsers from '../pages/AdminCustomerUsers';
import AdminUser from '../pages/AdminUser';
import CustomerForm from '../components/CustomerForm';

const CustomerRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminCustomers />} />
      <Route path="add" element={<CustomerForm />} />
      <Route path=":customerId/users" element={<AdminCustomerUsers />} />
      <Route path=":customerId/users/:userId" element={<AdminUser />} />
      <Route path=":customerId/users/add" element={<AdminUser />} />
    </Routes>
  );
};

export default CustomerRoutes;
