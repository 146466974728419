import { FC, useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material';
import * as centerSelectors from '../../domain/store/centers/selectors';
import { useSelector } from 'react-redux';
import { Center } from '../../domain/entities/Center';
import CenterForm from './CenterForm';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';

type Props = {
  onSettingCenter?: (center: Center | null) => void;
  onSelectedCenters?: (centers: number[]) => void;
  selectedCenters: number[];
};

const AvailableCenters: FC<Props> = ({
  onSettingCenter,
  onSelectedCenters,
  selectedCenters,
}) => {
  const [showModalFormCenter, setShowModalFormCenter] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [centerSelected, setCenterSelected] = useState<Center | null>(null);
  const centers = useSelector(centerSelectors.selectCenters);

  const handleToggle = (value: number) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onSelectedCenters?.(newChecked);
  };

  useEffect(() => {
    setChecked(selectedCenters);
  }, [selectedCenters]);

  return (
    <Box height={'100%'} overflow={'auto'}>
      <Grid container className="px-3">
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <Typography variant={'h6'} flex={1}>
            <ChevronRightIcon /> Centros permitidos
          </Typography>
          <IconButton
            size="small"
            onClick={() => setShowModalFormCenter(true)}
            sx={{ p: 0 }}
          >
            <img src={ICON_PLUS} alt="Nuevo centro" width={34} />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} style={{ height: 10 }}></Grid>
        <Grid item xs={12}>
          <List>
            {centers.map((center) => (
              <ListItem
                key={center.id}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="settings"
                    onClick={() => {
                      if (onSettingCenter)
                        onSettingCenter(
                          center.id === centerSelected?.id ? null : center
                        );
                      setCenterSelected(
                        center.id === centerSelected?.id ? null : center
                      );
                    }}
                  >
                    <img src={ICON_SETTINGS} alt="Configuración" width={14} />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => handleToggle(center.id)}
                  dense
                  selected={center.id === centerSelected?.id}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(center.id) !== -1}
                      edge="start"
                      inputProps={{ 'aria-labelledby': `center-${center.id}` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`center-${center.id}`}
                    primary={center.name}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* <Modal
        open={showModalFormCenter}
        onClose={() => setShowModalFormCenter(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <CenterForm />
        </Box>
      </Modal> */}
    </Box>
  );
};

export default AvailableCenters;
