import { FC, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MDrawer,
  Typography,
  styled,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Icons from '@mui/icons-material';
import {
  selectIsDrawerOpen,
  selectIsSmallScreen,
} from '../../domain/store/ui/selectors';
import { setIsDrawerOpen } from '../../domain/store/ui';
import * as sessionSelectors from '../../domain/store/session/selectors';
import * as customerActions from '../../domain/store/customers';
import * as sessionActions from '../../domain/store/session';
import * as centerActions from '../../domain/store/centers';

import { UserAccess } from '../../shared/enums/user-access.enum';
import ICON_DASHBOARD from '../../shared/assets/menu/dashboard.png';
import ICON_CUSTOMER from '../../shared/assets/menu/customers.png';
import ICON_SIGNOUT from '../../shared/assets/menu/signout.png';
import ICON_REPORT from '../../shared/assets/menu/report.png';
import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_NOTIFICATION from '../../shared/assets/menu/notification.png';
import ICON_LEFT_ARROW from '../../shared/assets/menu/icon_left_arrow.png';
import ICON_RIGHT_ARROW from '../../shared/assets/menu/icon_right_arrow.png';
import { makeStyles } from '@mui/styles';
import { imageFromBuffer } from '../../shared/utils/imageFromBuffer';

type Menu = {
  id: number;
  label: string;
  path: string;
  icon: any;
  type: 'mui' | 'custom';
};

const CustomListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  background: selected
    ? `linear-gradient(135deg, rgba(35, 69, 72, 1), rgba(68, 70, 73, 0.9))`
    : 'none',
  // Ajusta los colores y dirección del degradado según tus preferencias
}));

const useStyle = makeStyles({
  drawer: (data: { isDrawerOpen: boolean; isSmallScreen: boolean }) => ({
    width: !data.isDrawerOpen ? '60px !important' : '250px !important',
    overflow: 'hidden',
    backgroundImage: 'none !important',
  }),
  list: (data: { isDrawerOpen: boolean; isSmallScreen: boolean }) => ({
    width: !data.isDrawerOpen ? '60px' : '250px',
  }),
});

const Drawer: FC = () => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const currentUser = useSelector(sessionSelectors.selectUser);
  const location = useLocation();
  const classes = useStyle({
    isDrawerOpen,
    isSmallScreen,
  });
  const [profileImage, setProfileImage] = useState<string | undefined>(
    undefined
  );

  const handleCloseDrawer = () => {
    dispatch(setIsDrawerOpen(false));
  };

  const handleOpenDrawer = () => {
    dispatch(setIsDrawerOpen(true));
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(
      customerActions.setCustomers({
        customers: [],
        total: 0,
      })
    );
    dispatch(sessionActions.setUser(null));
    dispatch(centerActions.setCenters([]));
  };

  useEffect(() => {
    if (currentUser) {
      const availableMenus: Menu[] = [];

			if(currentUser.useDashboard) {
				availableMenus.push({
					id: 6,
					label: 'Dashboard',
					path: '/dashboard',
					icon: ICON_REPORT,
					type: 'custom',
				});
			}

      availableMenus.push({
        id: 1,
        label: 'Resumen de estado global',
        path: '/',
        icon: ICON_DASHBOARD,
        type: 'custom',
      });

      if (currentUser.access === UserAccess.SUPER_USER) {
        availableMenus.push({
          id: 2,
          label: 'Lista de usuarios',
          path: '/admin/customers',
          icon: ICON_CUSTOMER,
          type: 'custom',
        });

        availableMenus.push({
          id: 5,
          label: 'Configuración de clientes',
          path: '/root/customers',
          icon: ICON_SETTINGS,
          type: 'custom',
        });

        availableMenus.push({
          id: 4,
          label: 'Notificaciones',
          path: '/notification-center',
          icon: ICON_NOTIFICATION,
          type: 'custom',
        });

					availableMenus.push({
						id: 7,
						label: 'Configuración de dashboards',
						path: '/dashboard-config',
						icon: 'AutoAwesomeMosaic',
						type: 'mui',
					});
      }

      if (currentUser.access === UserAccess.ENERPRY) {
        availableMenus.push({
          id: 4,
          label: 'Notificaciones',
          path: '/notification-center',
          icon: ICON_NOTIFICATION,
          type: 'custom',
        });
      }

      /* availableMenus.push({
        id: 3,
        label: 'Reportes',
        path: '/reports',
        icon: 'Article',
        type: 'mui',
      }); */

      if (currentUser.picture && typeof currentUser.picture !== 'string') {
        const image = imageFromBuffer((currentUser.picture as any).data);
        setProfileImage(image);
      }

      if (currentUser.picture && typeof currentUser.picture === 'string') {
        setProfileImage(currentUser.picture);
      }

      setMenus(availableMenus);
    }
  }, [currentUser]);

  return (
    <MDrawer
      anchor="left"
      open={isSmallScreen ? isDrawerOpen : true}
      onClose={handleCloseDrawer}
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      classes={{
        paper: !isSmallScreen ? classes.drawer : classes.drawer,
      }}
      sx={{
        backgroundColor: isSmallScreen ? 'transparent !important' : undefined,
        borderColor: isSmallScreen ? 'transparent !important' : undefined,
        backgroundImage: 'none !important',
      }}
      style={{ overflow: 'hidden' }}
    >
      <div
        className="h-full flex flex-col relative"
        style={{
          width: isSmallScreen ? '100%' : isDrawerOpen ? '250px' : '60px',
        }}
      >
        {isSmallScreen && (
          <div className={`absolute top-3 left-3`} style={{ zIndex: 1200 }}>
            <ButtonBase
              sx={{
                padding: '5px',
              }}
              onClick={() =>
                isDrawerOpen ? handleCloseDrawer() : handleOpenDrawer()
              }
            >
              <Icons.MenuOutlined />
            </ButtonBase>
          </div>
        )}
        {!isSmallScreen && (
          <div
            className={`absolute ${
              isDrawerOpen ? 'top-8 right-6' : 'top-5 right-0'
            }`}
            style={{ zIndex: 1200 }}
          >
            <ButtonBase
              sx={{
                padding: '5px',
                borderRadius: '40%',
                background:
                  'linear-gradient(135deg, rgba(35, 69, 72, 1), rgba(68, 70, 73, 0.9))',
              }}
              onClick={() =>
                isDrawerOpen ? handleCloseDrawer() : handleOpenDrawer()
              }
            >
              <img
                width={6}
                src={isDrawerOpen ? ICON_LEFT_ARROW : ICON_RIGHT_ARROW}
                alt="Toggle Menu"
              />
            </ButtonBase>
          </div>
        )}
        <div className="flex-1">
          {!isSmallScreen && (
            <>
              {isDrawerOpen && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Avatar src={profileImage} />
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      marginTop: '5px',
                    }}
                  >
                    {currentUser?.name}
                  </Typography>
                  <Typography
                    component="small"
                    sx={{ fontSize: '10px', textDecoration: 'underline' }}
                  >
                    <Link to="/profile">Editar perfil</Link>
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Box
            sx={{
              width: 250,
              paddingRight: '10px',
              marginTop: !isSmallScreen ? '3rem' : '5rem',
            }}
            role="presentation"
            onClick={() => {
              if (isSmallScreen) handleCloseDrawer();
            }}
            onKeyDown={() => {
              if (isSmallScreen) handleCloseDrawer();
            }}
          >
            <List
              sx={{
                width: !isDrawerOpen ? '60px' : '250px',
              }}
            >
              {menus.map((menu) => {
                const currentPath = location.pathname;
                const isSelected =
                  menu.path === '/'
                    ? currentPath === '/'
                    : menu.path === '/dashboard' ? currentPath === '/dashboard' : location.pathname.startsWith(menu.path);

                const iconName: keyof typeof Icons = menu.icon;
                const IconComponent = Icons[iconName];
                return (
                  <ListItem disablePadding key={menu.id}>
                    <CustomListItemButton
                      onClick={() => {
                        navigate(menu.path);
                      }}
                      selected={isSelected}
                    >
                      <ListItemIcon sx={{ minWidth: '39px' }}>
                        {menu.type === 'mui' ? (
                          <IconComponent />
                        ) : (
                          <img src={menu.icon} alt={menu.label} width="24px" />
                        )}
                      </ListItemIcon>
                      {isDrawerOpen && (
                        <ListItemText
                          primary={
                            <Typography fontSize="10pt" variant="body1">
                              {menu.label}
                            </Typography>
                          }
                        />
                      )}
                    </CustomListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </div>
        <div
          className={`flex ${isSmallScreen ? 'flex-col items-start' : ''}`}
          style={{ paddingLeft: '11px', paddingBottom: '10px' }}
        >
          {isSmallScreen && (
            <Button
              startIcon={
                <Avatar
                  src={profileImage}
                  sx={{ width: 24, height: 24, marginRight: '8px' }}
                />
              }
              sx={{
                color: '#fff',
                textDecoration: 'underline',
                textTransform: 'none',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <Link
                to="/profile"
                onClick={() => {
                  handleCloseDrawer();
                }}
              >
                <Typography variant="body1" component="div">
                  {currentUser?.name}
                </Typography>
              </Link>
            </Button>
          )}
          <Button
            startIcon={
              <img
                src={ICON_SIGNOUT}
                width={24}
                alt="Signout"
                style={{ marginRight: '8px' }}
              />
            }
            onClick={handleLogout}
            sx={{
              color: '#fff',
              textDecoration: 'underline',
              textTransform: 'none',
              '&:hover': { backgroundColor: 'transparent' },
            }}
            disableRipple
            variant="text"
          >
            {isDrawerOpen && <>Cerrar sesión</>}
          </Button>
        </div>
      </div>
    </MDrawer>
  );
};

export default Drawer;
