import { Customer } from '../../domain/entities/Customer';
import { CustomerRepository } from '../../domain/repositories/CustomerRepository';
import http from '../api/http';

export class RestApiCustomerRepository implements CustomerRepository {
  async findCustomersPagination(
    page: number,
    limit: number
  ): Promise<{ customers: Customer[]; total: number }> {
    try {
      const response = await http.get(`/customers?page=${page}&limit=${limit}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
