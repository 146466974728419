import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const selectUser = (state: RootState) => state.user;

export const selectLoading = createSelector(
  [selectUser],
  (user) => user.loading
);

export const selectUsers = createSelector([selectUser], (user) => user.users);

export const selectError = createSelector([selectUser], (user) => user.error);
