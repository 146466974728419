import React from 'react';
import { Outlet, useLocation, Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Link, Typography, useTheme } from '@mui/material';

import ThemeProvider from './ThemeProvider';
import AppBar from './AppBar';
import { useSelector } from 'react-redux';
import {
  selectIsDrawerOpen,
  selectIsSmallScreen,
} from '../../domain/store/ui/selectors';
import { selectCustomer } from '../../domain/store/session/selectors';
import { Customer } from '../../domain/entities/Customer';
import AppBarLogos from './AppBarLogos';

const boxSize = 2; // Tamaño del cuadro de efecto de luz en píxeles

export const searchLogo = (customer: Customer | null): string => {
  if (!customer) return '';
  const buffer = String.fromCharCode.apply(null, customer.image.data);
  const image = window.btoa(buffer);
  return `data:image/png;base64, ${image}`;
};

const Layout: React.FC = () => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const location = useLocation();
  const theme = useTheme();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const currentCustomer = useSelector(selectCustomer);

  return (
    <ThemeProvider>
      {!isSmallScreen && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBarLogos />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar />
      </Box>
      <Box
        component="main"
        style={{
          marginLeft: isSmallScreen ? 0 : isDrawerOpen ? 250 : 60,
          paddingTop: isSmallScreen ? 10 : 5,
          marginTop: isSmallScreen ? '60px' : 0,
          height: isSmallScreen ? 'calc(100vh - 60px)' : 'calc(100vh - 70px)',
          paddingLeft: isSmallScreen ? 0 : '20px',
        }}
        sx={{
          backgroundColor: '#212630',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: isSmallScreen
              ? '100%'
              : `calc(100vw - ${isDrawerOpen ? '270px' : '80px'})`,
            //minHeight: 'calc(100% - 10rem)',
            marginTop: '10rem',
            //backgroundColor: '#121416',
            borderTopLeftRadius: isSmallScreen ? '0px' : '20px',
          },
          [theme.breakpoints.down('md')]: {
            '&::before': {
              height: 'calc(100vh - 10rem)',
              marginTop: '10rem',
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            overflowX: 'auto',
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to="/"
              component={RouterLink}
            >
              Inicio
            </Link>
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <Typography color="text.primary" key={routeTo}>
                  {name}
                </Typography>
              ) : (
                <Link
                  component={RouterLink}
                  underline="hover"
                  color="inherit"
                  to={routeTo}
                  key={routeTo}
                >
                  {name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
        <Box
          component="main"
          sx={{
            zIndex: 100,
            position: 'relative',
            pl: isSmallScreen ? 1 : 1,
            pr: isSmallScreen ? 1 : 1,
            marginTop: !isSmallScreen ? 0 : 0,
            paddingBottom: isSmallScreen ? '3rem' : 0,
            height: isSmallScreen ? '90%' : undefined,
            overflowY: isSmallScreen ? 'scroll' : undefined,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              minHeight: 'calc(100vh - 11rem)',
              height: '100%',
              marginTop: '6rem',
              backgroundColor: '#121416',
              zIndex: -1,
              borderTopLeftRadius: isSmallScreen ? '0px' : '20px',
            },
          }}
        >
          <Outlet />
        </Box>
        {currentCustomer && isSmallScreen && (
          <Box
            component="div"
            sx={{
              position: 'relative',
              bottom: '0',
              right: '0',
              left: 0,
              p: 2,
              backgroundColor: 'rgba(0,0,0,0.1)',
              zIndex: 1000,
            }}
            display={'flex'}
            alignContent={'center'}
            justifyContent={'center'}
          >
            <img
              src={searchLogo(currentCustomer)}
              alt={`Logo ${currentCustomer.name}`}
              width={100}
              style={{
                opacity: 0.4,
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: boxSize,
          height: boxSize,
          borderRadius: '50%',
          boxShadow: `0 0 ${isSmallScreen ? '150px' : '200px'} ${
            isSmallScreen ? '32px' : '60px'
          } #01E4DD`,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: boxSize,
          height: boxSize,
          borderRadius: '50%',
          boxShadow: `0 0 ${isSmallScreen ? '150px' : '200px'} ${
            isSmallScreen ? '32px' : '60px'
          } #01E4DD`,
          zIndex: 1100,
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: boxSize,
          height: boxSize,
          borderRadius: '50%',
          boxShadow: `0 0 ${isSmallScreen ? '150px' : '200px'} ${
            isSmallScreen ? '32px' : '60px'
          } #01E4DD`,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: boxSize,
          height: boxSize,
          borderRadius: '50%',
          boxShadow: `0 0 ${isSmallScreen ? '150px' : '200px'} ${
            isSmallScreen ? '32px' : '60px'
          } #01E4DD`,
          zIndex: 1100,
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: boxSize,
          height: boxSize,
          borderRadius: '50%',
          boxShadow: `0 0 ${isSmallScreen ? '150px' : '200px'} ${
            isSmallScreen ? '32px' : '60px'
          } #01E4DD`,
          zIndex: 1100,
        }}
      />
    </ThemeProvider>
  );
};

export default Layout;
