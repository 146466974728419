import { AppBar, Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  selectIsDrawerOpen,
  selectIsSmallScreen,
} from '../../domain/store/ui/selectors';
import { searchLogo } from './Layout';
import { selectCustomer } from '../../domain/store/session/selectors';
import ENERPRY_LOGO from '../../shared/assets/logo_dark.png';
import PRYVIEW_LOGO from '../../shared/assets/pryview_logo.png';

const AppBarLogos = () => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const currentCustomer = useSelector(selectCustomer);

  return (
    <AppBar
      position="static"
      style={{ paddingLeft: !isSmallScreen ? (isDrawerOpen ? 250 : 60) : 0 }}
      sx={{ boxShadow: 'none', background: '#212630' }}
    >
      <Box
        sx={{
          background: 'rgba(0,0,0,0.5)',
          p: 2,
          marginLeft: isDrawerOpen ? '30px' : '20px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <Stack
          direction="row"
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component="div">
            <img src={PRYVIEW_LOGO} alt={`Logo Pryview`} width={150} />
          </Box>
          <Box component="div">
            <img src={ENERPRY_LOGO} alt={`Logo Enerpry`} width={130} />
          </Box>
          {currentCustomer && (
            <Box component="div">
              <img
                src={searchLogo(currentCustomer)}
                alt={`Logo ${currentCustomer.name}`}
                width={120}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </AppBar>
  );
};

export default AppBarLogos;
