import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import ThemeProvider from '../components/ThemeProvider';

type Props = {
  isLoggedIn: boolean | null;
};

const UnauthenticatedRoutes: FC<Props> = ({ isLoggedIn }) => {
  return (
    <Routes>
      <Route
        element={
          isLoggedIn ? (
            <Navigate to="/" />
          ) : (
            <ThemeProvider>
              <Outlet />
            </ThemeProvider>
          )
        }
      >
        <Route path="/signin" element={<LoginPage />} />
      </Route>
    </Routes>
  );
};

export default UnauthenticatedRoutes;
