import { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Layout from '../components/Layout';
import CustomerRoutes from './CustomerRoutes';
import Reports from '../pages/Reports';
import EditProfile from '../pages/EditProfile';
import NotificationCenter from '../pages/NotificationCenter';
import RootRoutes from './rootRoutes';
import Dashboard from '../pages/Dashboard';
import DashboardConfig from '../pages/DashboardConfig';

type Props = {
  isLoggedIn: boolean | null;
};

const AuthenticatedRoutes: FC<Props> = ({ isLoggedIn }) => {
  return (
    <Routes>
      <Route
        element={
          !isLoggedIn && isLoggedIn !== null ? (
            <Navigate to="/auth/signin" />
          ) : (
            <Layout />
          )
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<Outlet />}>
          <Route path="customers/*" element={<CustomerRoutes />} />
        </Route>
        <Route path="/root" element={<Outlet />}>
          <Route path="customers/*" element={<RootRoutes />} />
        </Route>
        <Route path="reports" element={<Reports />} />
        <Route path="profile" element={<EditProfile />} />
        <Route path="notification-center" element={<NotificationCenter />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard-config" element={<DashboardConfig />} />
      </Route>
    </Routes>
  );
};

export default AuthenticatedRoutes;
