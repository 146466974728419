import * as userActions from './index';
import { put, takeEvery } from 'redux-saga/effects';
import userService from '../../../application/services/UserService';
import { User } from '../../entities/User';

function* handleLoadUsers(data: any) {
  const { payload } = data;
  try {
    yield put(userActions.setLoading(true));
    const response: User[] = yield userService.findByCustomerId(
      payload.customerId
    );
    yield put(userActions.setUsers(response));
  } catch (error: any) {
    yield put(userActions.setError(error.message));
  } finally {
    yield put(userActions.setLoading(false));
  }
}

export function* userSaga() {
  yield takeEvery(userActions.loadUsers.type, handleLoadUsers);
}
