import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import http from '../../infrastructure/api/http';
import * as customerSelectors from '../../domain/store/customers/selectors';

import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_REMOVE from '../../shared/assets/menu/icon_remove.png';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import CreateIcon from '@mui/icons-material/Create';
import centerService from '../../application/services/CenterService';
import { Center } from '../../domain/entities/Center';
import CenterForm from '../components/CenterForm';

const RootAdminCenters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [centers, setCenters] = useState<Center[]>([]);
  const [centerToEdit, setcenterToEdit] = useState<null | Center>(null);
  let { customerId } = useParams();
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const navigate = useNavigate();
  const selectedCustomer = useSelector(customerSelectors.selectSelected);

  const loadData = async (customerId: string) => {
    try {
      setIsLoading(true);
      const centers = await centerService.loadAllCentersByCustomerId(
        `${customerId}`
      );
      setCenters(centers);
    } catch (error) {
      alert(`Error al crear un centro`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      loadData(`${selectedCustomer.id}`);
    } else if (customerId) {
      loadData(customerId);
    }
  }, [selectedCustomer, customerId]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            Administrar Centros
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="flex-end"
          alignContent="end"
        >
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              //navigate(`/admin/customers/add`);
              setShowForm(true);
            }}
          >
            <img src={ICON_PLUS} alt="Nuevo cliente" width={48} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{
                backgroundColor: 'transparent',
                border: 0,
                width: isSmallScreen ? '100%' : '50%',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Centro
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Módulos
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Tipo Envío
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Acción
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={4}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 15,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {centers.map((center) => (
                  <TableRow
                    key={center.id}
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ p: 1 }}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {center.name}
                      </Box>
                    </TableCell>

                    <TableCell align="center">{center.countModules}</TableCell>

                    <TableCell align="center">{center.sendType}</TableCell>

                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          setcenterToEdit(center);
                          setShowForm(true);
                        }}
                      >
                        <CreateIcon style={{ fontSize: '14px' }} />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          navigate(`${center.id}/modules`);
                        }}
                      >
                        <img
                          src={ICON_SETTINGS}
                          alt="Configuración"
                          width={14}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={async () => {
                          if (
                            window.confirm(
                              '¿Está seguro que desea eliminar este centro?'
                            )
                          ) {
                            try {
                              await http.delete(`/centers/${center.id}`);
                              if (customerId) loadData(customerId);
                            } catch (error) {
                              alert(
                                'Se ha producido un error al remover el cliente'
                              );
                            }
                          }
                        }}
                      >
                        <img src={ICON_REMOVE} alt="Eliminar" width={14} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Modal
        open={showForm}
        onClose={() => {
          setShowForm(false);
          setcenterToEdit(null);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {customerId && (
            <CenterForm
              center={centerToEdit}
              customerId={parseInt(customerId)}
              onCreated={() => {
                if (customerId) loadData(customerId);
                setShowForm(false);
                setcenterToEdit(null);
              }}
              onCancel={() => {
                setShowForm(false);
                setcenterToEdit(null);
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RootAdminCenters;
