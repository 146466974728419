import jwt_decode from 'jwt-decode';
import { RestApiUserRepository } from '../../infrastructure/repositories/RestApiUserRepository';
import { User } from '../../domain/entities/User';

export class UserService {
  private restApiUserRepository = new RestApiUserRepository();

  async signInWithUsernameAndPassword(
    username: string,
    password: string,
    remember: boolean
  ): Promise<User> {
    const response = await this.restApiUserRepository.signIn(
      username,
      password,
      remember
    );
    localStorage.setItem('token', response.token);
    const tokenDecoded: any = jwt_decode(response.token);
    const user = await this.restApiUserRepository.findById(tokenDecoded.sub);
    return user;
  }

  async findUserById(userId: number): Promise<User> {
    return this.restApiUserRepository.findById(userId);
  }

  async findByCustomerId(customerId: number): Promise<User[]> {
    return this.restApiUserRepository.findByCustomerId(customerId);
  }
}

const instance = new UserService();

export default instance;
