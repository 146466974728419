import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const selectUI = (state: RootState) => state.ui;

export const selectTheme = createSelector([selectUI], (ui) => ui.theme);
export const selectIsSmallScreen = createSelector(
  [selectUI],
  (ui) => ui.isSmallScreen
);
export const selectIsDrawerOpen = createSelector(
  [selectUI],
  (ui) => ui.isDrawerOpen
);
