import { takeEvery, put } from 'redux-saga/effects';
import {
  setLogIn,
  setError,
  setLoading,
  setUser,
  validateSession,
} from './index';
import jwtDecode from 'jwt-decode';

import UserService from '../../../application/services/UserService';
import { User } from '../../entities/User';
import { AxiosError } from 'axios';

function* handleLogin(data: any) {
  const { payload } = data;
  try {
    yield put(setError(null));
    yield put(setLoading(true));
    const response: User = yield UserService.signInWithUsernameAndPassword(
      payload.username,
      payload.password,
      payload.remember
    );
    yield put(setUser(response));
  } catch (error: any | AxiosError) {
    const message = error.response?.data?.message || error.message;
    yield put(setError(message));
  } finally {
    yield put(setLoading(false));
  }
}

function* handleValidateSession() {
  try {
    yield put(setLoading(true));
    const token = localStorage.getItem('token');
    if (token) {
      const tokenDecoded: { sub: number; iat: number; exp: number } =
        jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime > tokenDecoded.exp) {
        localStorage.removeItem('token');
      } else {
        const response: User = yield UserService.findUserById(tokenDecoded.sub);
        yield put(setUser(response));
      }
    }
  } catch (error: any) {
    yield put(setError(error.message));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sessionSaga() {
  yield takeEvery(setLogIn.type, handleLogin);
  yield takeEvery(validateSession.type, handleValidateSession);
}
