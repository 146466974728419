import { FC, useEffect } from 'react';
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as customerSelectors from '../../domain/store/customers/selectors';
import * as customerActions from '../../domain/store/customers';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';
import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';

const AdminCustomers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerPagination = useSelector(customerSelectors.selectCustomers);
  const isLoading = useSelector(customerSelectors.selectLoading);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const error = useSelector(customerSelectors.selectError);

  useEffect(() => {
    dispatch(customerActions.loadCustomers({ page: 1, limit: 50 }));
  }, [dispatch]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            Lista de clientes
          </Typography>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Box
              style={{ display: 'flex', justifyContent: 'center', padding: 15 }}
            >
              <Alert severity="error">{error}</Alert>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '40px',
            px: 5,
            paddingRight: isSmallScreen ? '0' : undefined,
          }}
        >
          <TableContainer>
            <Table
              sx={{
                backgroundColor: 'transparent',
                border: 0,
                width: isSmallScreen ? '100%' : '50%',
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    'td, th': { border: 0 },
                  }}
                >
                  <TableCell>
                    <Typography fontWeight={'600'}>
                      <ChevronRightIcon /> Clientes
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={'600'}>Acción</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={2}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 15,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {customerPagination.customers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ padding: 1 }}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          sx={{
                            background:
                              'linear-gradient(135deg, rgba(5, 242, 219, 0.1), rgba(255, 255, 255, 0.1))',
                            color: '#fff',
                          }}
                          children={
                            <strong>{customer.name[0].toUpperCase()}</strong>
                          }
                        />
                        {customer.name}
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          dispatch(customerActions.setSelected(customer));
                          navigate(`/admin/customers/${customer.id}/users`);
                        }}
                      >
                        <img
                          src={ICON_SETTINGS}
                          alt="Configuración"
                          width={14}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminCustomers;
