import React, { FC, useEffect, useState } from 'react';
import http from '../../infrastructure/api/http';
import { User } from '../../domain/entities/User';
import { Center } from '../../domain/entities/Center';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Customer } from '../../domain/entities/Customer';

type Props = {
  centerStatus?: any;
  isModal?: boolean;
  onCancel?: () => void;
  onComplete?: () => void;
};

type FormData = {
  title: string;
  name: string;
  moid: string;
  divisor: number;
  center: number;
  showInLamps: boolean;
  users: number[];
};

const CenterStatusForm: FC<Props> = ({
  isModal,
  centerStatus,
  onCancel,
  onComplete,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<number | ''>('');
  const [users, setUsers] = useState<User[]>([]);
  const [centers, setCenters] = useState<Center[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const loadUsersByCustomerId = async (customerId: number) => {
    try {
      setUsers([]);
      const request = await http.get(`/customers/${customerId}/users`);
      setUsers(request.data);
    } catch (error) {
      alert('Error al cargar los usuarios');
    }
  };

  const loadCustomers = async () => {
    try {
      const request = await http.get(`/customers/all`);
      setCustomers(request.data);
    } catch (error) {
      alert('Error al cargar clientes');
    }
  };

  const loadCentersByCustomerId = async (customerId: number) => {
    try {
      setCenters([]);
      const request = await http.get(`/customers/${customerId}/centers`);
      setCenters(request.data);
    } catch (error) {
      alert('Error al cargar los usuarios');
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      if (centerStatus) {
        await http.put(`/center-status/${centerStatus.id}`, data);
      } else {
        await http.post(`/center-status`, data);
      }
      onComplete?.();
    } catch (error) {
      alert('Se ha producido un error al guardar el analizador de red');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCustomers();
  }, []);

  useEffect(() => {
    if (centerStatus) {
      setValue('title', centerStatus.title);
      setValue('name', centerStatus.name);
      setValue('moid', centerStatus.moid);
      setValue('divisor', centerStatus.divisor);
      setValue('center', centerStatus.center.id);
      setValue('showInLamps', centerStatus.showInLamps);
      setTimeout(() => {
        setValue(
          'users',
          Array.isArray(centerStatus.users)
            ? centerStatus.users.map((u: any) => u.id)
            : []
        );
      }, 1000);
      setSelectedCustomer(centerStatus.center.idCustomer);
    }
  }, [centerStatus]);

  useEffect(() => {
    if (selectedCustomer) {
      loadCentersByCustomerId(selectedCustomer);
      loadUsersByCustomerId(selectedCustomer);
    } else {
      setUsers([]);
      setValue('users', []);
      setCenters([]);
    }
  }, [selectedCustomer]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="title"
        control={control}
        rules={{ required: 'El título es obligatorio' }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Título"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.title}
            helperText={errors.title?.message}
          />
        )}
      />

      <Controller
        name="name"
        control={control}
        rules={{ required: 'La IP es obligatoria' }}
        render={({ field }) => (
          <TextField
            {...field}
            label="IP"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        )}
      />

      <Controller
        name="moid"
        control={control}
        rules={{ required: 'El MOID es obligatorio' }}
        render={({ field }) => (
          <TextField
            {...field}
            label="MOID"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.moid}
            helperText={errors.moid?.message}
          />
        )}
      />

      <Controller
        name="divisor"
        control={control}
        rules={{ required: 'El Multiplicador es obligatorio' }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label="Multiplicador"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.divisor}
            helperText={errors.divisor?.message}
          />
        )}
      />

      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Cliente</InputLabel>
        <Select
          label="Cliente"
          value={selectedCustomer}
          onChange={(ev) => {
            if (ev.target.value) {
              setSelectedCustomer(ev.target.value as number);
            } else {
              setSelectedCustomer('');
            }
          }}
        >
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Controller
        name="center"
        control={control}
        rules={{ required: 'El centro es obligatorio' }}
        defaultValue={centerStatus ? centerStatus.center.id : null}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.center}
          >
            <InputLabel>Centro</InputLabel>
            <Select {...field} label="Centro">
              {centers.map((center) => (
                <MenuItem key={center.id} value={center.id}>
                  {center.name}
                </MenuItem>
              ))}
            </Select>
            {errors.center && <p>{errors.center.message}</p>}
          </FormControl>
        )}
      />

      <Controller
        name="users"
        control={control}
        rules={{ required: 'Debes seleccionar al menos un usuario' }}
        defaultValue={[]}
        render={({ field }) => (
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.users}
          >
            <InputLabel>Usuarios</InputLabel>
            <Select
              {...field}
              multiple
              renderValue={(selected) =>
                selected
                  .map((id) => users.find((user) => user.id === id)?.name)
                  .join(', ')
              }
              input={<OutlinedInput label="Usuarios" />}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <Checkbox checked={field.value.includes(user.id)} />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
            {errors.users && (
              <Typography color="red">{errors.users.message}</Typography>
            )}
          </FormControl>
        )}
      />

      <FormControlLabel
        style={{ paddingBottom: 30, width: '100%' }}
        control={
          <Controller
            name="showInLamps"
            control={control}
            defaultValue={false}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={props.value !== undefined ? props.value : false}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label="Mostrar en dashboard lámparas"
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={loading}
      >
        {loading && <CircularProgress size={14} color="inherit" />}&nbsp;Guardar
      </Button>

      {isModal && (
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            onCancel?.();
          }}
          disabled={loading}
        >
          Cancelar
        </Button>
      )}
    </form>
  );
};

export default CenterStatusForm;
