import { Jail } from '../../domain/entities/Jail';
import { Lamp } from '../../domain/entities/Lamp';
import { Module } from '../../domain/entities/Module';
import { ModuleRepository } from '../../domain/repositories/ModuleRepository';
import http from '../api/http';

export class RestApiModuleRepository implements ModuleRepository {
  async findModulesByCenterId(centerId: string): Promise<Module[]> {
    try {
      const response = await http.get<Module[]>(`/centers/${centerId}/modules`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findAllModulesByCenterId(centerId: string): Promise<Module[]> {
    try {
      const response = await http.get<Module[]>(
        `/centers/${centerId}/all-modules`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findAllJailsByModuleId(
    centerId: string,
    moduleId: string
  ): Promise<Jail[]> {
    try {
      const response = await http.get<Jail[]>(
        `/centers/${centerId}/modules/${moduleId}/jails`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findAllLampsByJailId(
    jailId: string,
    centerId: string,
    moduleId: string
  ): Promise<Lamp[]> {
    try {
      const response = await http.get<Lamp[]>(
        `/centers/${centerId}/modules/${moduleId}/jails/${jailId}/lamps`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async create(module: Module): Promise<Module> {
    try {
      const response = await http.post<Module>(
        `/centers/${module.centerId}/modules`,
        module
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createLamp(
    name: string,
    moduleId: number,
    centerId: number,
    jailId: number
  ): Promise<Lamp> {
    try {
      const response = await http.post<Lamp>(
        `/centers/${centerId}/modules/${moduleId}/jails/${jailId}/lamps`,
        {
          name,
          moduleId,
          centerId,
          jailId,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateLamp(
    name: string,
    lampId: number,
    jailId: number,
    moduleId: number,
    centerId: number
  ): Promise<Jail> {
    try {
      const response = await http.put<Jail>(
        `/centers/${centerId}/modules/${moduleId}/jails/${jailId}/lamps/${lampId}`,
        {
          name,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createJail(
    name: string,
    moduleId: number,
    centerId: number
  ): Promise<Jail> {
    try {
      const response = await http.post<Jail>(
        `/centers/${centerId}/modules/${moduleId}/jails`,
        {
          name,
          moduleId,
          centerId,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateJail(
    jailId: number,
    name: string,
    moduleId: number,
    centerId: number
  ): Promise<Jail> {
    try {
      const response = await http.put<Jail>(
        `/centers/${centerId}/modules/${moduleId}/jails/${jailId}`,
        {
          name,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async update(module: Module): Promise<Module> {
    try {
      const response = await http.put<Module>(
        `/centers/${module.centerId}/modules`,
        module
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
