import { Button, Input, Stack, Typography } from '@mui/material'
import { useState } from 'react';

enum STEPS {
	REQUEST_RECOVERY_PASSWORD,
	SUBMIT_NEW_PASSWORD
}

const RecoveryPassword = () => {
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(STEPS.REQUEST_RECOVERY_PASSWORD);
	const [email, setEmail] = useState('');
	const [pin, setPin] = useState('');
	const [newPassword, setNewPassword] = useState('');

	const handleRecoveryPassword = async () => {
		if(!email) {
			alert('Por favor ingrese el correo electrónico');
			return;
		}
		setLoading(true);
		try {
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setStep(STEPS.SUBMIT_NEW_PASSWORD);
		} catch (error) {
			alert('Se ha producido un error al intentar solicitar el pin para reestablecer la contraseña');
		} finally {
			setLoading(false);
		}
	}

	if(step === STEPS.REQUEST_RECOVERY_PASSWORD)
		return (
			<Stack gap={1}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					Recuperar contraseña
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					Por favor introduzca el correo asociada a la cuenta a recuperar. A este será enviado un PIN para realizar el cambio de contraseña.
				</Typography>
				<Input value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder='Correo electrónico' fullWidth disabled={loading} />
				<Button variant='contained' type="button" disabled={loading} onClick={handleRecoveryPassword}>Recuperar contraseña</Button>
			</Stack>
		)

		return (
			<Stack gap={1}>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					Recuperar contraseña
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					Ingrese el código enviado a su correo asociado y digite la nueva contraseña
				</Typography>
				<Input value={pin} onChange={(ev) => setPin(ev.target.value)} placeholder='PIN de recuperación' fullWidth />
				<Input value={newPassword} onChange={(ev) => setNewPassword(ev.target.value)} type="password" placeholder='Nueva contraseña' fullWidth />
				<Button type="button" variant='contained'>Cambiar contraseña</Button>
				<Button type="button" variant='text' onClick={() => {
					setStep(STEPS.REQUEST_RECOVERY_PASSWORD);
				}}>Intenar nuevamente</Button>
			</Stack>
		)
}

export default RecoveryPassword