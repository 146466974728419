import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import http from '../../infrastructure/api/http';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import CenterStatusForm from '../components/CenterStatusForm';

const DashboardConfig = () => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rows, setRows] = useState<any[]>([]);

  const reloadData = () => {
    setRows([]);
    setSelectedRow(null);
    setShowModal(false);
    setShowModal(false);
    loadData();
  };

  const loadData = async () => {
    try {
      const request = await http.get('/center-status/all');
      setRows(request.data);
    } catch (error) {
      alert('Error al cargar los usuarios');
    }
  };

  const deleteCenterStatus = async (centerStatus: any) => {
    if (!window.confirm(`Deseas eliminar a ${centerStatus.title}`)) return;
    try {
      await http.put(`/center-status/${centerStatus.id}`, {
        status: false,
      });
      reloadData();
    } catch (error) {
      alert(`Error al eliminar el analizador de red ${centerStatus.title}`);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box
      component="div"
      height="100%"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" flex={1}>
            Configuración de dashboard
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Nuevo analizador de red
          </Button>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 30 }}>
          <TableContainer sx={{ borderRadius: '15px' }}>
            <Table
              sx={{
                minWidth: 650,
                backgroundColor: 'rgba(0,0,0,0.09)',
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: '#212630' }}>
                  <TableCell>Titulo</TableCell>
                  <TableCell>Identificador</TableCell>
                  <TableCell align="right">Centro</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      IP: {row.name} - MOID: {row.moid}
                    </TableCell>
                    <TableCell align="right">{row.center.name}</TableCell>
                    <TableCell align="center">
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedRow(row);
                            setShowModal(true);
                          }}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteCenterStatus(row)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Modal
        open={showModal}
        onClose={() => {
          setSelectedRow(null);
          setShowModal(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '100%' : '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography id="server-modal-title" variant="h6" component="h2">
              Analizador de Red
            </Typography>
            <CenterStatusForm
              onCancel={() => {
                setSelectedRow(null);
                setShowModal(false);
              }}
              onComplete={() => {
                reloadData();
              }}
              isModal={true}
              centerStatus={selectedRow}
            />
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardConfig;
