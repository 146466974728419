import { FC, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import AuthenticatedRoutes from './authenticatedRoutes';
import UnauthenticatedRoutes from './unauthenticatedRoutes';
import * as sessionSelectors from '../../domain/store/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { validateSession } from '../../domain/store/session';

const AppRoutes: FC = () => {
  const isSessionLoading = useSelector(sessionSelectors.selectLoading);
  const currentUser = useSelector(sessionSelectors.selectUser);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateSession());
  }, [dispatch]);

  useEffect(() => {
    if (isSessionLoading && !currentUser) {
      setIsAuthenticated(null);
    }

    if (currentUser) {
      setIsAuthenticated(true);
    }

    if (!isSessionLoading && !currentUser) {
      setIsAuthenticated(false);
    }
  }, [isSessionLoading, currentUser]);

  return (
    <Routes>
      <Route
        path="/auth/*"
        element={<UnauthenticatedRoutes isLoggedIn={isAuthenticated} />}
      />
      <Route
        path="/*"
        element={<AuthenticatedRoutes isLoggedIn={isAuthenticated} />}
      />
      <Route path="*" element={<>NOT FOUND</>} />
    </Routes>
  );
};

export default AppRoutes;
