import React, { FC } from 'react';

type CircleProgressProps = {
  progress: number;
  label: React.ReactNode;
  color: string;
  size: number;
};

const CircleProgress: FC<CircleProgressProps> = ({
  progress,
  label,
  color,
  size,
}) => {
  const strokeWidth = 10; // Grosor del borde del círculo
  const radius = size / 2 - strokeWidth / 2; // Radio del círculo basado en el tamaño
  const normalizedRadius = radius; // Radio normalizado
  const circumference = normalizedRadius * 2 * Math.PI; // Circunferencia del círculo

  // Ajuste para que inicie desde las 7 hrs y termine en las 5 hrs
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="flex items-center justify-center relative">
      <svg height={size} width={size}>
        <circle
          stroke="#ddd"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={size / 2}
          cy={size / 2}
          transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotar para que empiece desde la parte superior
        />
      </svg>
      <div className="absolute font-bold">{label}</div>
    </div>
  );
};

export default CircleProgress;
