import { Customer } from '../../domain/entities/Customer';
import { RestApiCustomerRepository } from '../../infrastructure/repositories/RestApiCustomerRepository';

export class CustomerService {
  private restApiCustomerRepository = new RestApiCustomerRepository();

  async loadCustomers(
    page: number,
    limit: number
  ): Promise<{ customers: Customer[]; total: number }> {
    return this.restApiCustomerRepository.findCustomersPagination(page, limit);
  }
}

const instance = new CustomerService();

export default instance;
