import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

type Props = {
  selectedVariableSetting: any;
  handleSettingsClose: (data: FormData) => void;
  onComplete?: (data: FormData) => void;
  onCancel: () => void;
};

type FormData = {
  minPoint: number;
  maxPoint: number;
  multiplier: number;
  adjust: number;
  type: string | null;
};

const VariableSettingConfig: FC<Props> = ({
  selectedVariableSetting,
  handleSettingsClose,
  onCancel,
}) => {
  const [setting, setSetting] = useState<FormData>({
    adjust: 0,
    maxPoint: 4.5,
    minPoint: 0.5,
    multiplier: 1,
    type: null,
  });

  const onSubmit = () => {
    handleSettingsClose?.(setting);
  };

  useEffect(() => {
    setSetting({
      adjust: selectedVariableSetting?.settings?.adjust,
      maxPoint: selectedVariableSetting?.settings?.maxPoint,
      minPoint: selectedVariableSetting?.settings?.minPoint,
      multiplier: selectedVariableSetting?.settings?.multiplier,
      type: null,
    });
  }, [selectedVariableSetting]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
          xs: '80%',
          md: '50%',
          xl: '30%',
        },
        bgcolor: '#c2c2c2',
        borderRadius: '15px',
        color: 'black',
        boxShadow: 24,
        p: 2,
      }}
    >
      <Typography variant="h6" component="h2">
        Configuración de variable {selectedVariableSetting?.variable?.name}
      </Typography>
      <Box component="div" sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography>Set point mín.</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={setting.minPoint}
              onChange={(e) => {
                setSetting({ ...setting, minPoint: +e.target.value });
              }}
              style={{
                backgroundColor: '#2d738f',
                color: 'black',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Set point máx.</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={setting.maxPoint}
              onChange={(e) => {
                setSetting({ ...setting, maxPoint: +e.target.value });
              }}
              style={{
                backgroundColor: '#2d738f',
                color: 'black',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Multiplicador</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={setting.multiplier}
              onChange={(e) => {
                setSetting({ ...setting, multiplier: +e.target.value });
              }}
              style={{
                backgroundColor: '#2d738f',
                color: 'black',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography>Ajuste +/-</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              type="number"
              fullWidth
              value={setting.adjust}
              onChange={(e) => {
                setSetting({ ...setting, adjust: +e.target.value });
              }}
              style={{
                backgroundColor: '#2d738f',
                color: 'black',
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            type="button"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            sx={{ textTransform: 'none' }}
          >
            Guardar
          </Button>
          <Button
            type="button"
            variant="outlined"
            disableElevation
            onClick={onCancel}
            sx={{ textTransform: 'none' }}
            style={{ marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default VariableSettingConfig;
