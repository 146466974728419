import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL } from '../../shared/constants/api';

const http = axios.create({
  baseURL: API_URL,
});

http.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default http;
