import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const selectCustomer = (state: RootState) => state.customer;

export const selectLoading = createSelector(
  [selectCustomer],
  (customer) => customer.loading
);

export const selectCustomers = createSelector(
  [selectCustomer],
  (customer) => customer.customers
);

export const selectError = createSelector(
  [selectCustomer],
  (customer) => customer.error
);

export const selectSelected = createSelector(
  [selectCustomer],
  (customer) => customer.selected
);
