import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Customer } from '../../entities/Customer';
import { Center } from '../../entities/Center';

type CustomerProp = {
  customers: Customer[];
  total: number;
};

export interface CustomerState {
  customers: CustomerProp;
  centers: Center[];
  loading: boolean;
  error: string | null;
  selected: Customer | null;
}

const initialState: CustomerState = {
  customers: { customers: [], total: 0 },
  centers: [],
  loading: false,
  error: null,
  selected: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomers(state, action: PayloadAction<CustomerProp>) {
      state.customers = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    loadCustomers(
      state,
      action: PayloadAction<{ page: number; limit: number }>
    ) {},
    setSelected(state, action: PayloadAction<Customer | null>) {
      state.selected = action.payload;
    },
  },
});

export const {
  setCustomers,
  setLoading,
  loadCustomers,
  setError,
  setSelected,
} = customerSlice.actions;

export default customerSlice.reducer;
