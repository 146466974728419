import { FC } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import LoginForm from '../components/LoginForm';
import BG_IMAGE from '../../shared/assets/background_login.png';
import BG_IMAGE_MOBILE from '../../shared/assets/bg_login_mobile.png';
import BG_IMAGE_LOGO from '../../shared/assets/bg_info_login.png';
import LOGO from '../../shared/assets/isotipo_enerpry.png';
import LOGO_PRYVIEW from '../../shared/assets/pryview_logo.png';

const LoginPage: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${!isSmallScreen ? BG_IMAGE : BG_IMAGE_MOBILE})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
      }}
    >
      <Container
        component={'main'}
        maxWidth={'lg'}
        style={{ height: '100%', display: 'flex', alignItems: 'center' }}
      >
        <Box component="div" flex={1}>
          <Grid container>
            {isSmallScreen && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: !isSmallScreen ? '-5rem' : '0rem',
                  marginBottom: '1rem',
                }}
              >
                <img
                  src={LOGO_PRYVIEW}
                  alt="Logo"
                  className={!isSmallScreen ? "w-100" : "w-80"}
                  style={{ height: !isSmallScreen ? '10rem' : '2rem', alignSelf: 'center' }}
                />
                <Typography
                  component="h1"
                  align="center"
                  style={{ marginTop: !isSmallScreen ? '-3.5rem' : '0rem' }}
                  fontSize="7.5pt"
                >
                  Sistema de monitoreo de fotoperiodo en tiempo real
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={5}
              style={{
                backgroundColor: '#1E1E1E',
								marginLeft: isSmallScreen ? '20px' : '0',
								marginRight: isSmallScreen ? '20px' : '0',
              }}
              className={`${isSmallScreen ? 'p-5' : 'p-2'} lg:p-14 rounded-tr-xl rounded-br-xl shadow ${isSmallScreen ? 'rounded-bl-xl rounded-tl-xl' : ''}`}
            >
              <div className="flex flex-col justify-center items-center">
                <img src={LOGO} alt="logo" className={`${isSmallScreen ? 'w-12' : 'w-16'} mb-1`} />
                <Typography component={'h1'} variant={'h5'} fontWeight={'600'} fontSize={isSmallScreen ? '1rem' : undefined}>
                  Iniciar sesión
                </Typography>
                <LoginForm />
              </div>
            </Grid>
            {!isSmallScreen && (
              <Grid
                item
                xs={0}
                md={7}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '90%',
                    backgroundImage: `url(${BG_IMAGE_LOGO})`,
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    component="h1"
                    style={{ marginTop: '4rem', marginLeft: '5rem' }}
                    fontSize={'9.5pt'}
                    letterSpacing={'0.5px'}
                  >
                    Sistema de monitoreo de fotoperiodo en tiempo real
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default LoginPage;
