import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../entities/User';

export interface UserState {
  users: User[];
  loading: boolean;
  error: string | null;
  selected: User | null;
}

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
  selected: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUsers(state, action: PayloadAction<{ customerId: number }>) {},
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
  },
});

export const { loadUsers, setLoading, setError, setUsers } = userSlice.actions;

export default userSlice.reducer;
