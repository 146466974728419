import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/es';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import http from '../../infrastructure/api/http';
import ICON_SEARCH from '../../shared/assets/menu/icon_search.png';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';

dayjs.extend(isSameOrBefore);

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID Notificación',
    filterable: false,
    width: 300,
    sortable: false,
    hideable: false,
    disableColumnMenu: true,
  },
  {
    field: 'alertConfig.center.name',
    headerName: 'Centro',
    filterable: false,
    sortable: false,
    hideable: false,
    disableColumnMenu: true,
    width: 300,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.alertConfig.center.name}`,
  },
  {
    field: 'alertConfig.variable.name',
    headerName: 'Variable',
    filterable: false,
    sortable: false,
    hideable: false,
    disableColumnMenu: true,
    width: 250,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.alertConfig.variable.name}`,
  },
  {
    field: 'description',
    headerName: 'Descripción',
    filterable: false,
    sortable: false,
    hideable: false,
    disableColumnMenu: true,
    width: 500,
  },
];

const NotificationCenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 50,
  });
  const [startAt, setStartAt] = useState<dayjs.Dayjs | null>(dayjs());
  const [endAt, setEndAt] = useState<dayjs.Dayjs | null>(dayjs());
  const isSmallScreen = useSelector(selectIsSmallScreen);

  const handleSearch = async (restart = false) => {
    const diffDays = endAt?.diff(startAt, 'days');
    if (diffDays !== undefined) {
      if (diffDays > 15) {
        return alert('Excediste el periodo de busqueda, máximo 15 dias');
      }

      if (restart) {
        setPagination({
          page: 0,
          pageSize: 50,
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await http.get(
          `/notifications/${startAt?.toISOString()}/${endAt?.toISOString()}?page=${
            pagination.page + 1
          }&limit=${pagination.pageSize}`
        );
        if (response.data.length > 0) {
          setTotalRows(response.data[1]);
          setRows(response.data[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [endAt, pagination, startAt]);

  return (
    <Box
      component="div"
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{ mb: 5 }}
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
        >
          <Typography
            variant="h5"
            fontSize={isSmallScreen ? '1.4rem' : undefined}
            flex={1}
          >
            Centro de notificaciones
          </Typography>
          <IconButton
            size="large"
            onClick={() => handleSearch(true)}
            disabled={isLoading}
            sx={{ p: 0 }}
          >
            <img src={ICON_SEARCH} alt="Buscar" width={48} />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={startAt}
                onChange={(date) => setStartAt(date)}
                label="Fecha de inicio"
                maxDate={endAt}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={endAt}
                onChange={(date) => setEndAt(date)}
                label="Fecha final"
                minDate={startAt}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} style={{ height: '60vh' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: pagination,
              },
            }}
            paginationMode="server"
            pageSizeOptions={[50, 100]}
            loading={isLoading}
            pagination
            rowCount={totalRows}
            paginationModel={pagination}
            onPaginationModelChange={(model) => {
              setPagination(model);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationCenter;
