import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, FieldErrors, UseFormRegister } from 'react-hook-form';
import ProfileAvatar from './ProfileAvatar';
import { useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';

type Props = {
  register?: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  userId: string | undefined;
  control: any;
  onChangePicture?: (image: string, file: File) => void;
};

const UserForm: FC<Props> = ({
  register,
  errors,
  userId,
  control,
  onChangePicture,
}) => {
  const currentUser = useSelector(selectUser);

  return (
    <Grid container spacing={2}>
      {`${currentUser?.id}` === `${userId}` && (
        <Grid item xs={12} md={12}>
          <ProfileAvatar
            onChangePicture={(image, file) => {
              onChangePicture?.(image, file);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <label htmlFor="txtUsername">Usuario</label>
        <TextField
          id="txtUsername"
          variant="outlined"
          fullWidth
          error={!!errors?.username}
          helperText={errors?.username && 'El usuario es requerido'}
          {...register?.('username', { required: true })}
          disabled={!!userId}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <label htmlFor="txtNames">Nombre</label>
        <TextField
          id="txtNames"
          variant="outlined"
          fullWidth
          error={!!errors?.names}
          helperText={errors?.names && 'El nombre es requerido'}
          {...register?.('names', { required: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <label htmlFor="txtEmail">Email</label>
        <TextField
          id="txtEmail"
          variant="outlined"
          fullWidth
          error={!!errors?.email}
          helperText={errors?.email && 'El email debe ser válido'}
          {...register?.('email', {
            required: true,
            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <label htmlFor="txtPassword">Contraseña</label>
        <TextField
          id="txtPassword"
          variant="outlined"
          fullWidth
          type="password"
          error={!!errors?.password}
          helperText={errors?.password && 'La contraseña es requerida'}
          {...register?.('password', {
            required: userId ? false : true,
          })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <label htmlFor="txtPhone">Teléfono</label>
        <TextField
          id="txtPhone"
          variant="outlined"
          fullWidth
          error={!!errors?.phone}
          helperText={errors?.phone && 'El número es requerido'}
          {...register?.('phone', {
            required: true,
          })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControlLabel
          style={{ paddingTop: 30 }}
          control={
            <Controller
              name="useDashboard"
              control={control}
              defaultValue={false}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value !== undefined ? props.value : false}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Dashboard"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControlLabel
          style={{ paddingTop: 30 }}
          control={
            <Controller
              name="isElectricOnly"
              control={control}
              defaultValue={false}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value !== undefined ? props.value : false}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Sólo electrico"
        />
      </Grid>
    </Grid>
  );
};

export default UserForm;
