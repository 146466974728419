import { Center } from '../../domain/entities/Center';
import { CenterRepository } from '../../domain/repositories/CenterRepository';
import http from '../api/http';

export class RestApiCenterRepository implements CenterRepository {
  async findCentersByCustomerId(customerId: string): Promise<Center[]> {
    try {
      const response = await http.get(`/customers/${customerId}/centers`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findAllCentersByCustomerId(customerId: string): Promise<Center[]> {
    try {
      const response = await http.get(`/customers/${customerId}/centers/all`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findAllCentersByCustomerIdAndUserId(
    customerId: string
  ): Promise<Center[]> {
    try {
      const response = await http.get(
        `/customers/${customerId}/centers/assigned`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async create(center: Center): Promise<Center> {
    try {
      const response = await http.post('/centers', center);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async update(center: Center): Promise<Center> {
    try {
      const response = await http.put(`/centers/${center.id}`, center);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
