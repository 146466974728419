import { FC, useState, useEffect } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  ButtonBase,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import UserForm from '../components/UserForm';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';
import http from '../../infrastructure/api/http';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { updatePicture } from '../../domain/store/session';

type FormData = {
  username: string;
  names: string;
  email: string;
  password: string;
  phone: string;
  status: boolean;
  notifyEmail: boolean;
};

const EditProfile: FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [httpErrors, setHttpErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [changeImage, setChangeImage] = useState('');
  const [changeImageFile, setChangeImageFile] = useState<File | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const response = await http.get(`/users/${currentUser.id}`);
        setValue('username', response.data.username);
        setValue('names', response.data.name);
        setValue('email', response.data.email);
        setValue('phone', response.data.phone);
        setValue('notifyEmail', response.data.notifyEmail);
        setValue('status', true);
      })();
    }
  }, [currentUser, setValue]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setHttpErrors(null);
    try {
      const formData = new FormData();
      formData.append('email', data.email);
      formData.append('names', data.names);
      formData.append('notifyEmail', data.notifyEmail ? 'true' : 'false');
      formData.append('password', data.password);
      formData.append('phone', data.phone);
      formData.append('status', data.status ? 'true' : 'false');
      formData.append('username', data.username);
      if (changeImageFile) formData.append('picture', changeImageFile);

      await http.put(`/users/${currentUser?.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (changeImage) {
        dispatch(
          updatePicture(changeImage)
        );
      }
      alert(`Se ha actualizado correctamente`);
    } catch (error: any) {
      setHttpErrors(error.response.data.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        sx={{ p: 2, width: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              fontSize={isSmallScreen ? '20px' : undefined}
            >
              Editar Perfil
            </Typography>
          </Grid>
          {httpErrors && (
            <Grid item xs={12}>
              <Alert severity="error">{httpErrors}</Alert>
            </Grid>
          )}
          <Grid item xs={12} height={10}></Grid>
          <Grid item xs={12}>
            <UserForm
              userId={`${currentUser?.id}`}
              control={control}
              register={register}
              errors={errors}
              onChangePicture={(image, file) => {
                setChangeImage(image);
                setChangeImageFile(file);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="notifyEmail"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Notificaciones por correo"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBase
              type="submit"
              sx={{
                mt: 3,
                mb: 2,
                background: 'linear-gradient(45deg, #009581 30%, #00AF9C 90%)',
                color: '#fff',
                p: '5px',
                borderRadius: '5px',
                width: isSmallScreen ? '100%' : '12rem',
              }}
              disabled={isLoading}
            >
              Actualizar
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditProfile;
