import { FC, useState, useEffect } from 'react';
import { selectCenters } from '../../domain/store/centers/selectors';
import { useSelector } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import { Center } from '../../domain/entities/Center';

type Props = {
  onSelect?: (center: Center) => void;
  centerId?: string | null;
};

const SelectCenter: FC<Props> = ({ onSelect, centerId }) => {
  const [selectedCenter, setSelectedCenter] = useState<number | ''>('');
  const centers = useSelector(selectCenters);

  useEffect(() => {
    if (centerId) {
      setSelectedCenter(parseInt(centerId));
      const centerSelected = centers.find(
        (center) => center.id === parseInt(centerId)
      );
      if (onSelect && centerSelected) onSelect(centerSelected);
    }
  }, [centerId, centers]);

  return (
    <Select
      label="Centro"
      onChange={(e) => {
        setSelectedCenter(e.target.value as number);
        const centerSelected = centers.find(
          (center) => center.id === e.target.value
        );
        if (onSelect && centerSelected) onSelect(centerSelected);
      }}
      value={selectedCenter}
      fullWidth
    >
      {centers.length === 0 ? (
        <MenuItem value={''} selected disabled>
          No hay centros
        </MenuItem>
      ) : (
        centers
          /* .sort((a, b) =>
            a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
          ) */
          .map((center) => (
            <MenuItem key={center.id} value={center.id}>
              {center.name}
            </MenuItem>
          ))
      )}
    </Select>
  );
};

export default SelectCenter;
