import { Jail } from '../../domain/entities/Jail';
import { Lamp } from '../../domain/entities/Lamp';
import { Module } from '../../domain/entities/Module';
import { RestApiModuleRepository } from '../../infrastructure/repositories/RestApiModuleRepository';

export class ModuleService {
  private restApiModuleRepo = new RestApiModuleRepository();

  async loadModulesByCenterId(centerId: string): Promise<Module[]> {
    return this.restApiModuleRepo.findModulesByCenterId(centerId);
  }

  async loadAllModulesByCenterId(centerId: string): Promise<Module[]> {
    return this.restApiModuleRepo.findAllModulesByCenterId(centerId);
  }

  async loadAllJailsByModuleId(
    centerId: string,
    moduleId: string
  ): Promise<Jail[]> {
    return this.restApiModuleRepo.findAllJailsByModuleId(centerId, moduleId);
  }

  async loadAllLampsByJailId(
    jailId: string,
    centerId: string,
    moduleId: string
  ): Promise<Lamp[]> {
    return this.restApiModuleRepo.findAllLampsByJailId(
      jailId,
      centerId,
      moduleId
    );
  }

  async createJail(name: string, moduleId: number, centerId: number) {
    return this.restApiModuleRepo.createJail(name, moduleId, centerId);
  }

  async createLamp(
    name: string,
    moduleId: number,
    centerId: number,
    jailId: number
  ) {
    return this.restApiModuleRepo.createLamp(name, moduleId, centerId, jailId);
  }

  async updateLamp(
    name: string,
    moduleId: number,
    centerId: number,
    jailId: number,
    lampId: number
  ) {
    return this.restApiModuleRepo.updateLamp(
      name,
      lampId,
      jailId,
      moduleId,
      centerId
    );
  }

  async updateJail(
    jailId: number,
    name: string,
    moduleId: number,
    centerId: number
  ) {
    return this.restApiModuleRepo.updateJail(jailId, name, moduleId, centerId);
  }

  async create(module: Module): Promise<Module> {
    return this.restApiModuleRepo.create(module);
  }

  async update(module: Module): Promise<Module> {
    return this.restApiModuleRepo.update(module);
  }
}

const instance = new ModuleService();

export default instance;
