import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, Grid, FormControl } from '@mui/material';
import moduleService from '../../application/services/ModuleService';
import { Module } from '../../domain/entities/Module';

type Props = {
  onCancel?: () => void;
  onCreated?: () => void;
  centerId: number;
  module?: Module | null;
};

type FormData = {
  nombre: string;
  jaulas: string;
};

const ModuleForm: FC<Props> = ({ onCancel, onCreated, centerId, module }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      if (!module) {
        await moduleService.create({
          name: data.nombre,
          countJails: parseInt(data.jaulas),
          centerId: centerId,
        } as any);
      } else {
        await moduleService.update({
          name: data.nombre,
          countJails: parseInt(data.jaulas),
          centerId: centerId,
          id: module.id,
        } as any);
      }
      onCreated?.();
    } catch (error) {
      alert(`Se ha producido un error al guardar el módulo`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (module) {
      setValue('nombre', module.name);
      setValue('jaulas', `${module.countJails}`);
    }
  }, [module]);

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Nombre"
              variant="outlined"
              error={!!errors.nombre}
              helperText={errors.nombre && 'El nombre es requerido'}
              {...register('nombre', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Jaulas"
              variant="outlined"
              type="number"
              error={!!errors.jaulas}
              helperText={errors.jaulas && 'La cantidad de jaulas es requerido'}
              {...register('jaulas', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
              onCancel?.();
            }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModuleForm;
