import { FC, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material';
import http from '../../infrastructure/api/http';
import VariableSettingConfig from './VariableSettingConfig';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import VariableRedSettingConfig from './VariableRedSettingConfig';

type CenterVariable = {
  centerId: number;
  twoAr: boolean;
  variables: Array<{
    variableId: number;
    settings: {
      minPoint: number;
      maxPoint: number;
      multiplier: number;
      adjust: number;
      type: string | null;
    };
  }>;
};

type Variable = {
  id: number;
  name: string;
  isDefault: boolean;
};

type checkedValue = {
  variableId: number;
  settings: {
    minPoint: number;
    maxPoint: number;
    multiplier: number;
    adjust: number;
    type: string | null;
  };
  red?: Array<{
    minPoint: number;
    maxPoint: number;
    multiplier: number;
    adjust: number;
    type: string | null;
  }>;
  variable?: Variable;
};

type Props = {
  userId: number;
  center: CenterVariable;
  onChange?: (data: checkedValue[]) => void;
};

const VariableSettings: FC<Props> = ({ center, onChange, userId }) => {
  const [variables, setVariables] = useState<Variable[]>([]);
  const [checked, setChecked] = useState<checkedValue[]>([]);
  const [selectedVariableSetting, setSelectedVariableSetting] =
    useState<checkedValue | null>(null);
  const [openConfigurer, setOpenConfigurer] = useState(false);
  const [openConfigurerRed, setOpenConfigurerRed] = useState(false);

  const handleSettingsClick = (vs: Variable) => {
    const checkedIndex = checked.findIndex((c) => c.variableId === vs.id);
    if (checkedIndex !== -1) {
      setSelectedVariableSetting({
        settings: checked[checkedIndex].settings,
        variableId: vs.id,
        variable: vs,
        red: (checked.find((c) => c.variableId === vs.id) as any)?.data
          ? (checked.find((c) => c.variableId === vs.id) as any)?.data.map(
              (c: any) => ({
                adjust: c.adjust,
                maxPoint: c.maxPoint,
                minPoint: c.minPoint,
                multiplier: c.multiplier,
                type: c.type,
              })
            )
          : checked
              .filter((c) => c.variableId === vs.id)
              .map((c) => ({
                adjust: c.settings.adjust,
                maxPoint: c.settings.maxPoint,
                minPoint: c.settings.minPoint,
                multiplier: c.settings.multiplier,
                type: c.settings.type,
              })),
      });
      if (vs.id === 2) {
        setOpenConfigurerRed(true);
      } else {
        setOpenConfigurer(true);
      }
    }
  };

  const handleSettingsClose = () => {
    setOpenConfigurer(false);
    setOpenConfigurerRed(false);
    setSelectedVariableSetting(null);
  };

  const handleToggle = (value: number) => {
    const currentIndex = checked.findIndex((c) => c.variableId === value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push({
        variableId: value,
        settings: {
          minPoint: 0,
          maxPoint: 0,
          multiplier: 0,
          adjust: 0,
          type: null,
        },
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange?.(newChecked);
  };

  useEffect(() => {
    (async () => {
      const response = await http.get<Variable[]>('/variables');
      setVariables(response.data);
      setChecked(center.variables);
    })();
  }, [center]);

  useEffect(() => {
    (async () => {
      try {
        const response = await http.get<
          Array<{
            id: number;
            createdAt: string;
            updatedAt: string;
            adjustment: number;
            multiplier: number;
            min: number;
            max: number;
            variableId: number;
            centerId: number;
            type: string | null;
          }>
        >(`/centers/${center.centerId}/settings/${userId}`);

        if (response.data.length > 0) {
          setChecked(
            response.data.map((s) => ({
              variableId: s.variableId,
              settings: {
                adjust: s.adjustment,
                maxPoint: s.max,
                minPoint: s.min,
                multiplier: s.multiplier,
                type: s.type,
              },
              variable: variables.find((v) => v.id === s.variableId),
            }))
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [center, variables, userId]);

  return (
    <Box height={'100%'} overflow={'auto'}>
      <Grid container className="px-3">
        <Grid item xs={12}>
          <Typography variant={'h6'}>
            <ChevronRightIcon /> Configuración de variables
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ height: 10 }}></Grid>
        <Grid item xs={12}>
          <List>
            {variables.map((vs) => (
              <ListItem
                key={vs.id}
                disablePadding
                secondaryAction={
                  vs.id === 12 ? undefined : (
                    <IconButton
                      edge="end"
                      aria-label="settings"
                      onClick={() => {
                        handleSettingsClick(vs);
                      }}
                    >
                      <img src={ICON_SETTINGS} alt="Configuración" width={14} />
                    </IconButton>
                  )
                }
              >
                <ListItemButton
                  disabled={vs.isDefault}
                  onClick={() => handleToggle(vs.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        checked.findIndex((c) => c.variableId === vs.id) !== -1
                      }
                      edge="start"
                      inputProps={{ 'aria-labelledby': `setting-${vs.id}` }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`setting-${vs.id}`}
                    primary={`${vs.name}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Modal open={openConfigurer} onClose={handleSettingsClose}>
        <>
          {selectedVariableSetting && (
            <VariableSettingConfig
              onCancel={() => {
                handleSettingsClose();
              }}
              handleSettingsClose={(data) => {
                const checkedIndex = checked.findIndex(
                  (c) => c.variableId === selectedVariableSetting.variableId
                );

                if (checkedIndex !== -1) {
                  const newChecked = [...checked];
                  newChecked[checkedIndex].settings = data;
                  setChecked(newChecked);
                  onChange?.(newChecked);
                }

                handleSettingsClose();
              }}
              selectedVariableSetting={selectedVariableSetting}
            />
          )}
        </>
      </Modal>
      <Modal open={openConfigurerRed} onClose={handleSettingsClose}>
        <>
          {selectedVariableSetting && (
            <VariableRedSettingConfig
              twoAr={center.twoAr}
              handleSettingsClose={(data) => {
                const checkedIndex = checked.findIndex(
                  (c) => c.variableId === selectedVariableSetting.variableId
                );

                if (checkedIndex !== -1) {
                  const newChecked = [...checked];
                  newChecked[checkedIndex].red = data;
                  setChecked(newChecked);
                  onChange?.(newChecked);
                }

                handleSettingsClose();
              }}
              onCancel={() => {
                handleSettingsClose();
              }}
              selectedVariableSetting={selectedVariableSetting}
            />
          )}
        </>
      </Modal>
    </Box>
  );
};

export default VariableSettings;
