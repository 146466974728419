import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

const selectSession = (state: RootState) => state.session;

export const selectLoading = createSelector(
  [selectSession],
  (session) => session.loading
);
export const selectUser = createSelector(
  [selectSession],
  (session) => session.user
);
export const selectError = createSelector(
  [selectSession],
  (session) => session.error
);
export const selectCustomer = createSelector(
  [selectSession],
  (session) => session.customer
);
