import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

type FormData = {
  minPoint: number;
  maxPoint: number;
  multiplier: number;
  adjust: number;
  type: string | null;
};

type Props = {
  selectedVariableSetting: any;
  handleSettingsClose: (data: FormData[]) => void;
  onComplete?: (data: FormData) => void;
  onCancel: () => void;
  twoAr: boolean;
};

const types = ['va', 'vb', 'vc', 'ia', 'ib', 'ic', 'fa', 'fb', 'fc'];

const VariableRedSettingConfig: FC<Props> = ({
  selectedVariableSetting,
  handleSettingsClose,
  onCancel,
  twoAr,
}) => {
  const [setting, setSetting] = useState<FormData[]>(
    types.map((type, index) => {
      let max = 4.5;
      let min = 0.5;

      if (index >= 0 && index <= 2) {
        max = 245;
        min = 0;
      }

      if (index >= 3 && index <= 5) {
        max = 100;
        min = 0;
      }

      return {
        adjust: 0,
        type: type,
        maxPoint: max,
        minPoint: min,
        multiplier: 1,
      };
    })
  );
  const [setting2, setSetting2] = useState<FormData[]>(
    types.map((type, index) => {
      let max = 4.5;
      let min = 0.5;

      if (index >= 0 && index <= 2) {
        max = 245;
        min = 0;
      }

      if (index >= 3 && index <= 5) {
        max = 100;
        min = 0;
      }

      return {
        adjust: 0,
        type: type,
        maxPoint: max,
        minPoint: min,
        multiplier: 1,
      };
    })
  );

  const onSubmit = () => {
    handleSettingsClose?.(setting);
  };

  useEffect(() => {
    setSetting(
      selectedVariableSetting.red.map((r: any) => ({
        adjust: r.adjust,
        maxPoint: r.maxPoint,
        minPoint: r.minPoint,
        multiplier: r.multiplier,
        type: r.type,
      }))
    );
  }, [selectedVariableSetting]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
          xs: '90%',
          md: '80%',
          xl: '80%',
        },
        height: '90%',
        bgcolor: '#c2c2c2',
        borderRadius: '15px',
        color: 'black',
        boxShadow: 24,
        p: 2,
        overflowY: 'auto',
      }}
    >
      <Grid container spacing={2}>
        {twoAr && (
          <Grid
            item
            xs={12}
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={5}
          >
            <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              AR 1
            </div>
            <div style={{ cursor: 'pointer' }}>AR 2</div>
          </Grid>
        )}
        {types.map((t) => (
          <Grid item xs={12} md={4} key={t}>
            <Typography variant="h6" component="h2">
              Configuración de variable {t.toUpperCase()}
            </Typography>
            <Box component="div" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography>Set point mín.</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={setting.find((s) => s.type === t)?.minPoint}
                    onChange={(e) => {
                      setSetting(
                        [...setting].map((s) => {
                          if (s.type === t)
                            return { ...s, minPoint: +e.target.value };
                          return s;
                        })
                      );
                    }}
                    style={{
                      backgroundColor: '#2d738f',
                      color: 'black',
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography>Set point máx.</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={setting.find((s) => s.type === t)?.maxPoint}
                    onChange={(e) => {
                      setSetting(
                        [...setting].map((s) => {
                          if (s.type === t)
                            return { ...s, maxPoint: +e.target.value };
                          return s;
                        })
                      );
                    }}
                    style={{
                      backgroundColor: '#2d738f',
                      color: 'black',
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography>Multiplicador</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={setting.find((s) => s.type === t)?.multiplier}
                    onChange={(e) => {
                      setSetting(
                        [...setting].map((s, idx2) => {
                          if (s.type === t)
                            return { ...s, multiplier: +e.target.value };
                          return s;
                        })
                      );
                    }}
                    style={{
                      backgroundColor: '#2d738f',
                      color: 'black',
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography>Ajuste +/-</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={setting.find((s) => s.type === t)?.adjust}
                    onChange={(e) => {
                      setSetting(
                        [...setting].map((s) => {
                          if (s.type === t)
                            return { ...s, adjust: +e.target.value };
                          return s;
                        })
                      );
                    }}
                    style={{
                      backgroundColor: '#2d738f',
                      color: 'black',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            type="button"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            sx={{ textTransform: 'none' }}
          >
            Guardar
          </Button>
          <Button
            type="button"
            variant="outlined"
            disableElevation
            onClick={onCancel}
            sx={{ textTransform: 'none' }}
            style={{ marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VariableRedSettingConfig;
