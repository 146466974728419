import { FC, useEffect, useState } from 'react';
import { AppBar as MAppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from './Drawer';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsSmallScreen,
  selectTheme,
} from '../../domain/store/ui/selectors';
import LOGO_LIGHT from '../../shared/assets/logo_light.png';
import LOGO_DARK from '../../shared/assets/logo_dark.png';
import LOGO_PRYVIEW from '../../shared/assets/pryview_enerpry_logo.png';
import { setIsDrawerOpen } from '../../domain/store/ui';

const AppBar: FC = () => {
  const [hasScroll, setHasScroll] = useState(false);
  const dispatch = useDispatch();
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const theme = useSelector(selectTheme);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isScrolled = scrollTop > 0;
      setHasScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <MAppBar
      position="fixed"
      style={{ paddingLeft: !isSmallScreen ? 240 : 0 }}
      sx={{
        background: hasScroll ? 'rgba(0,0,0,0.5)' : 'transparent',
        boxShadow: 'none',
      }}
    >
      {isSmallScreen && (
        <Toolbar>
          {isSmallScreen ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                dispatch(setIsDrawerOpen(true));
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div>
              <img
                width={120}
                src={theme === 'light' ? LOGO_LIGHT : LOGO_DARK}
                alt="Logo enerpry"
              />
            </div>
          )}
          <Box
            flex={1}
            justifyContent={'center'}
            display={'flex'}
            marginRight={'48px'}
          >
            <img width={180} src={LOGO_PRYVIEW} alt="Logo Pryview" />
          </Box>
        </Toolbar>
      )}
      <Drawer />
    </MAppBar>
  );
};

export default AppBar;
