import React, { useState, ChangeEvent, useEffect } from 'react';
import { Avatar, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';
import { imageFromBuffer } from '../../shared/utils/imageFromBuffer';

type Props = {
  onChangePicture?: (base64: string, file: File) => void;
};

const ProfileAvatar: React.FC<Props> = ({ onChangePicture }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const currentUser = useSelector(selectUser);

	useEffect(() => {
		if(currentUser && currentUser.picture && typeof currentUser.picture !== 'string') {
			const image = imageFromBuffer((currentUser.picture as any).data);
			setSelectedImage(image);
		}

		if(currentUser && currentUser.picture && typeof currentUser.picture === 'string') {
			setSelectedImage(currentUser.picture);
		}
	}, [currentUser]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size <= 500 * 1024) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result as string);
          onChangePicture?.(reader.result as string, file);
        };
        reader.readAsDataURL(file);
      } else {
        alert('El archivo excede el tamaño máximo permitido (500 KB)');
      }
    }
  };

  return (
    <div>
      <Avatar
        alt="Profile Image"
        src={selectedImage || ''}
        sx={{
          width: 100,
          height: 100,
        }}
      />
      <input
        type="file"
        accept=".jpg, .jpeg"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        id="avatar-upload"
      />
      <label htmlFor="avatar-upload">
        <Button
          variant="contained"
          component="span"
          sx={{ textTransform: 'none', mt: 1 }}
        >
          Cargar foto
        </Button>
      </label>
    </div>
  );
};

export default ProfileAvatar;
