import { FC, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import centerService from '../../application/services/CenterService';
import { Center } from '../../domain/entities/Center';

type Props = {
  onCancel?: () => void;
  onCreated?: () => void;
  customerId: number;
  center?: Center | null;
};

type FormData = {
  nombre: string;
  modulos: number;
  tipoenvio: string;
  datalogger: string;
  datalogger_mac: string;
  lamp_mac: string;
  dos_ar: number;
};

const CenterForm: FC<Props> = ({ onCancel, onCreated, customerId, center }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasDatalogger, setHasDatalogger] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      if (!center) {
        await centerService.create({
          lampMac: data.lamp_mac,
          dataloggerMac: data.datalogger_mac || '',
          countModules: data.modulos,
          datalogger: data.datalogger || '',
          name: data.nombre,
          sendType: data.tipoenvio,
          customerId: customerId,
          hasTwoAr: data.dos_ar,
        } as any);
      } else {
        await centerService.update({
          id: center.id,
          lampMac: data.lamp_mac,
          dataloggerMac: data.datalogger_mac || '',
          countModules: data.modulos,
          datalogger: data.datalogger || '',
          name: data.nombre,
          sendType: data.tipoenvio,
          customerId: customerId,
          hasTwoAr: data.dos_ar,
        } as any);
      }
      onCreated?.();
    } catch (error) {
      alert(`Se ha producido un error al guardar el centro`);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (center) {
      setValue('nombre', center.name);
      setValue('modulos', center.countModules);
      setValue('tipoenvio', center.sendType || '');
      setValue('datalogger', center.datalogger);
      setValue('datalogger_mac', center.dataloggerMac);
      setValue('lamp_mac', center.lampMac);
      setValue('dos_ar', (center as any).twoAr);
      setHasDatalogger(center.dataloggerMac !== '');
    }
  }, [center, setValue]);

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflowY: 'auto',
        maxHeight: '80vh',
        paddingTop: '10px',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Nombre"
              variant="outlined"
              error={!!errors.nombre}
              helperText={errors.nombre && 'El nombre es requerido'}
              {...register('nombre', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Módulos"
              variant="outlined"
              type="number"
              error={!!errors.modulos}
              helperText={errors.modulos && 'El módulo es requerido'}
              {...register('modulos', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Tiene 2 AR</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select label="Tiene 2 AR" onChange={onChange} value={value}>
                  <MenuItem value={1}>Si</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              )}
              name="dos_ar"
              defaultValue={0}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Tipo de envío</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de envío" onChange={onChange} value={value}>
                  <MenuItem value={''}>Seleccione</MenuItem>
                  <MenuItem value={'ftp'}>FTP</MenuItem>
                  <MenuItem value={'mysql'}>MySQL</MenuItem>
                </Select>
              )}
              name="tipoenvio"
              defaultValue={''}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Lámparas MAC"
              variant="outlined"
              {...register('lamp_mac')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>¿Tiene datalogger?</InputLabel>
            <Select
              fullWidth
              label="¿Tiene datalogger?"
              placeholder="¿Tiene datalogger?"
              value={hasDatalogger ? 1 : 0}
              onChange={(newVal) => {
                setHasDatalogger(!!parseInt(`${newVal?.target?.value}`));
              }}
            >
              <MenuItem value={1}>Si</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {hasDatalogger && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Datalogger</InputLabel>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Select label="Datalogger" onChange={onChange} value={value}>
                    <MenuItem value={''}>Seleccione</MenuItem>
                    <MenuItem value={'ftp'}>FTP</MenuItem>
                    {/* <MenuItem value={'mysql'}>MySQL</MenuItem> */}
                  </Select>
                )}
                name="datalogger"
                defaultValue={''}
                control={control}
              />
            </FormControl>
          </Grid>
        )}
        {hasDatalogger && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Datalogger MAC"
                variant="outlined"
                {...register('datalogger_mac')}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
              onCancel?.();
            }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CenterForm;
