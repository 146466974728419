import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import uiReduce from './ui';
import sessionReduce from './session';
import customerReduce from './customers';
import userReduce from './users';
import centerReduce from './centers';

import { sessionSaga } from './session/saga';
import { customerSaga } from './customers/saga';
import { userSaga } from './users/saga';
import { centerSaga } from './centers/saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    ui: uiReduce,
    session: sessionReduce,
    customer: customerReduce,
    user: userReduce,
    center: centerReduce,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

function* rootSaga() {
  yield all([sessionSaga(), customerSaga(), userSaga(), centerSaga()]);
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
