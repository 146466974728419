import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import RootAdmin from '../pages/RootAdmin';
import RootAdminCenter from '../pages/RootAdminCenter';
import RootAdminCenterModules from '../pages/rootAdminCenterModules';
import RootAdminCenterModuleJails from '../pages/RootAdminCenterModuleJails';
import RootAdminCenterModuleJailLamps from '../pages/RootAdminCenterModuleJailLamps';

const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<RootAdmin />} />
      <Route path="/:customerId/centers" element={<RootAdminCenter />} />
      <Route
        path="/:customerId/centers/:centerId/modules"
        element={<RootAdminCenterModules />}
      />
      <Route
        path="/:customerId/centers/:centerId/modules/:moduleId/jails"
        element={<RootAdminCenterModuleJails />}
      />
      <Route
        path="/:customerId/centers/:centerId/modules/:moduleId/jails/:jailId/lamps"
        element={<RootAdminCenterModuleJailLamps />}
      />
    </Routes>
  );
};

export default RootRoutes;
