import { FC, useEffect, useState } from 'react';
import http from '../../infrastructure/api/http';
import { useDispatch } from 'react-redux';
import SelectCenter from './SelectCenter';
import * as centerActions from '../../domain/store/centers';
import { Center } from '../../domain/entities/Center';
import { Jail } from '../../domain/entities/Jail';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Status } from '../../domain/entities/Status';
import { ArrowBackOutlined } from '@mui/icons-material';
import { Module } from '../../domain/entities/Module';
import ModuleJail from './ModuleJail';
import CenterStatusHistory from './CenterStatusHistory';
import { VariableResult } from '../../domain/entities/VariableResult';
import { Variable } from '../../domain/entities/Variable';

import RAYA_ICON_DL from '../../shared/assets/datalogger/raya.png';
import DIA_SOLEADO_ICON_DL from '../../shared/assets/datalogger/dia-soleado.png';
import TERMOMETRO_ICON_DL from '../../shared/assets/datalogger/termometro.png';
import FLECHA_ONDULANTE_ICON_DL from '../../shared/assets/datalogger/flecha-ondulante.png';
import VIENTO_ICON_DL from '../../shared/assets/datalogger/viento.png';
import HUMEDAD_ICON_DL from '../../shared/assets/datalogger/humedad.png';
import LLUVIA_ICON_DL from '../../shared/assets/datalogger/lluvia.png';

const dataloggerIcons = [
  {
    name: 'raya',
    src: RAYA_ICON_DL,
  },
  {
    name: 'dia-soleado',
    src: DIA_SOLEADO_ICON_DL,
  },
  {
    name: 'termometro',
    src: TERMOMETRO_ICON_DL,
  },
  {
    name: 'flecha-ondulante',
    src: FLECHA_ONDULANTE_ICON_DL,
  },
  {
    name: 'viento',
    src: VIENTO_ICON_DL,
  },
  {
    name: 'humedad',
    src: HUMEDAD_ICON_DL,
  },
  {
    name: 'lluvia',
    src: LLUVIA_ICON_DL,
  },
];

type Props = {
  showBack?: boolean;
  onBack?: () => void;
  centerId?: string | null;
  customerId: number;
};

const DashboardCenter: FC<Props> = ({
  onBack,
  showBack,
  centerId,
  customerId,
}) => {
  const dispatch = useDispatch();
  const [centerSelected, setCenterSelected] = useState<Center | null>(null);
  const [modules, setModules] = useState<Module[]>([]);
  const [status, setStatus] = useState<Status[]>([]);
  const [variables, setVariables] = useState<
    Array<{ variable: Variable; results: VariableResult[] }>
  >([]);

  const loadData = async () => {
    const requests = Promise.all([
      http.get<Module[]>(`/centers/${centerSelected!.id}/modules`),
      http.get<{ status: Status[]; variables: VariableResult[] }>(
        `/centers/${centerSelected!.id}/status`
      ),
    ]);
    const response = await requests;

    setModules(response[0].data);
    setStatus(response[1].data.status);
    const variablesResults = response[1].data.variables;
    const variables: Array<{
      variable: Variable;
      results: VariableResult[];
    }> = [];
    variablesResults.forEach((variableResult) => {
      const idx = variables.findIndex(
        (variable) => variable.variable.id === variableResult.variable.id
      );

      if (idx === -1) {
        variables.push({
          variable: variableResult.variable,
          results: [variableResult],
        });
      } else {
        variables[idx].results.push(variableResult);
      }
    });
    setVariables(variables);
  };

  useEffect(() => {
    if (centerSelected) {
      setModules([]);
      setStatus([]);
      setVariables([]);
      loadData();

      const interval = setInterval(() => {
        loadData();
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {
      setModules([]);
      setStatus([]);
      setVariables([]);
    };
  }, [centerSelected]);

  useEffect(() => {
    setModules([]);
    setStatus([]);
    setVariables([]);
    if (customerId) dispatch(centerActions.loadCentersByCustomerId(customerId));
  }, [customerId]);

  return (
    <Box sx={{ height: '100%', mt: 2 }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          {showBack && (
            <Tooltip title="Volver">
              <IconButton onClick={() => onBack?.()}>
                <ArrowBackOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Box component="div" className="w-52">
            <SelectCenter
              onSelect={(center) => {
                setCenterSelected(center);
              }}
              centerId={centerId}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Grid container spacing={2}>
            {modules.map((module) => (
              <Grid item xs={12} sm={12} md={6} key={module.id}>
                <ModuleJail module={module} selectedCenter={centerSelected} />
              </Grid>
            ))}
            {variables.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent sx={{ p: '10px' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1" fontWeight={600}>
                          Variables meteorológicas
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ borderColor: '#009581' }} />
                      </Grid>
                      {variables.map(({ variable, results }) => (
                        <Grid item xs={12} md={6} key={variable.id}>
                          <Grid container spacing={1}>
                            <Grid
                              item
                              xs={8}
                              display={'flex'}
                              alignItems={'center'}
                              flexDirection={'row'}
                              gap={2}
                            >
                              {dataloggerIcons.find(
                                (icon) => icon.name === variable.icon
                              )?.src && (
                                <img
                                  src={
                                    dataloggerIcons.find(
                                      (icon) => icon.name === variable.icon
                                    )?.src
                                  }
                                  alt={variable.name}
                                  width={18}
                                />
                              )}

                              <Typography variant="body1" fontWeight={500}>
                                {variable.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {results.find((result) => result.isIstant)
                                ?.result || '-'}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Grid container spacing={1}>
            {status.map((st, index) => (
              <Grid item xs={12} sm={12} key={st.centerId}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent sx={{ p: '10px' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body1" fontWeight={600}>
                              Analizador de red eléctrica {index + 1}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ borderColor: '#009581' }} />
                          </Grid>
                          <Grid item xs={12} className="overflow-x-auto">
                            <table className="w-full">
                              <thead>
                                <tr>
                                  <th style={{ paddingRight: 8 }}>
                                    <Typography variant="body1">
                                      VOLTAJE
                                    </Typography>
                                  </th>
                                  <th style={{ paddingRight: 8 }}>
                                    <Typography variant="body1">
                                      CORRIENTE
                                    </Typography>
                                  </th>
                                  <th style={{ paddingRight: 8 }}>
                                    <Typography variant="body1">
                                      FRECUENCIA
                                    </Typography>
                                  </th>
                                  <th>
                                    <Typography variant="body1">
                                      POTENCIA
                                    </Typography>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.vfa} V
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.cfa} A
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.ffa} Hz
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.pafa}{' '}kW
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.vfb} V
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.cfb} A
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.ffb} Hz
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
																			{st.pafb}{' '}kW
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.vfc} V
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.cfc} A
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
                                      {st.ffc} Hz
                                    </Typography>
                                  </td>
                                  <td align="center">
                                    <Typography variant="body1">
																			{st.pafc}{' '}kW
                                    </Typography>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ borderColor: '#009581' }} />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            <Typography variant="subtitle1">
                              Potencia total:
                            </Typography>
                            <Typography variant="subtitle1" fontWeight={600}>
														{(
                                Math.round(
                                  ((st.vfa * st.cfa +
                                    st.vfb * st.cfb +
                                    st.vfc * st.cfc) /
                                    1000) *
                                    100
                                ) / 100
                              ).toFixed(1)}{' '}
                              kW
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {status.map((st, index) => (
              <Grid item xs={12} key={st.centerId}>
                <CenterStatusHistory centerId={st.centerId} idx={index + 1} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardCenter;
