import { FC, useState } from 'react';
import {
  Alert,
  Box,
  ButtonBase,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as sessionSelector from '../../domain/store/session/selectors';
import * as uiSelector from '../../domain/store/ui/selectors';
import * as sessionActions from '../../domain/store/session';
import RecoveryPassword from './RecoveryPassword';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

type FormValues = {
  username: string;
  password: string;
  remember: boolean;
};

const LoginForm: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();
	const [showRecoveryPassword, setShowRecoveryPassword] = useState(false);
  const isLoading = useSelector(sessionSelector.selectLoading);
  const isSmallScreen = useSelector(uiSelector.selectIsSmallScreen);
  const error = useSelector(sessionSelector.selectError);
  const dispatch = useDispatch();

  const handleOnSubmit = (data: FormValues) => {
    dispatch(
      sessionActions.setLogIn({
        username: data.username,
        password: data.password,
        remember: data.remember,
      })
    );
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleOnSubmit)}
      noValidate
      sx={{ width: '90%' }}
    >
      <Box sx={{ width: '100%' }}>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      <Box sx={{ width: '100%', mt: 5 }}>
        <label htmlFor="username">Usuario</label>
        <TextField
          error={!!errors.username}
          margin="normal"
          required
          fullWidth
          id="username"
          autoComplete="username"
          helperText={errors.username && 'Ingresa tu nombre de usuario'}
          sx={{ marginTop: 0 }}
          {...register('username', { required: true, /* value: 'shernaez' */ })}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <label htmlFor="password">Contraseña</label>
        <TextField
          error={!!errors.password}
          margin="normal"
          required
          fullWidth
          type="password"
          id="password"
          autoComplete="current-password"
          helperText={errors.password && 'Ingresa tu contraseña'}
          sx={{ marginTop: 0 }}
          {...register('password', { required: true, /* value: 'norkraken' */ })}
        />
      </Box>
      <Box component="div" className="flex flex-row flex-nowrap items-center">
        <FormControlLabel
          control={<Checkbox value={true} color="primary" />}
          label={
            <Typography variant="body1" sx={{ fontSize: isSmallScreen ? '10px' : '13px' }}>
              Recuérdame
            </Typography>
          }
          style={{ flex: 1 }}
          sx={{ fontSize: '5px' }}
          {...register('remember')}
        />
        <Link onClick={() => {
					setShowRecoveryPassword(true);
				}} variant="body2" sx={{ fontSize: isSmallScreen ? '10px' : '13px' }} color="#fff" textAlign={'right'}>
          ¿Olvidaste la contraseña?
        </Link>
      </Box>
      <Box component="div" className="flex flex-row flex-nowrap justify-center">
        <ButtonBase
          type="submit"
          sx={{
            mt: 3,
            mb: 2,
            background: 'linear-gradient(45deg, #009581 30%, #00AF9C 90%)',
            color: '#fff',
            p: '5px',
            borderRadius: '5px',
            width: isSmallScreen ? '9rem' : '12rem',
          }}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={14} color="inherit" />}
          &nbsp;Iniciar sesión
        </ButtonBase>
      </Box>

			<Modal
				open={showRecoveryPassword}
				onClose={() => {
					setShowRecoveryPassword(false);
				}}
				keepMounted
			>
				<Box sx={{...style, width: isSmallScreen ? '90%' : '50%'}}>
					<RecoveryPassword />
				</Box>
			</Modal>
    </Box>
  );
};

export default LoginForm;
