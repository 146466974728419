import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import http from '../../infrastructure/api/http';
import * as customerSelectors from '../../domain/store/customers/selectors';
import * as customerActions from '../../domain/store/customers';

import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_REMOVE from '../../shared/assets/menu/icon_remove.png';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { imageFromBuffer } from '../../shared/utils/imageFromBuffer';
import CustomerForm from '../components/CustomerForm';
import CreateIcon from '@mui/icons-material/Create';
import { Customer } from '../../domain/entities/Customer';

const RootAdmin = () => {
  const [showForm, setShowForm] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState<null | Customer>(null);
  const customerPagination = useSelector(customerSelectors.selectCustomers);
  const isLoading = useSelector(customerSelectors.selectLoading);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customerActions.loadCustomers({ page: 1, limit: 50 }));
  }, [dispatch]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            Administrar clientes
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="flex-end"
          alignContent="end"
        >
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              //navigate(`/admin/customers/add`);
              setShowForm(true);
            }}
          >
            <img src={ICON_PLUS} alt="Nuevo cliente" width={48} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{
                backgroundColor: 'transparent',
                border: 0,
                width: isSmallScreen ? '100%' : '50%',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'}>Empresa</Typography>
                  </TableCell>
                  {
                    <TableCell style={{ borderBottomColor: 'transparent' }}>
                      <Typography fontWeight={'600'} align="center">
                        Acción
                      </Typography>
                    </TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={3}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 15,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {customerPagination.customers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ p: 1 }}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          sx={{
                            background:
                              'linear-gradient(135deg, rgba(5, 242, 219, 0.1), rgba(255, 255, 255, 0.1))',
                            color: '#fff',
                          }}
                          children={
                            <strong>{customer.name[0].toUpperCase()}</strong>
                          }
                        />
                        {customer.name}
                      </Box>
                    </TableCell>

                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          setCustomerToEdit(customer);
                          setShowForm(true);
                        }}
                      >
                        <CreateIcon style={{ fontSize: '14px' }} />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          dispatch(customerActions.setSelected(customer));
                          navigate(`/root/customers/${customer.id}/centers`);
                        }}
                      >
                        <img
                          src={ICON_SETTINGS}
                          alt="Configuración"
                          width={14}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={async () => {
                          if (
                            window.confirm(
                              '¿Está seguro que desea eliminar este cliente?'
                            )
                          ) {
                            try {
                              await http.delete(`/customers/${customer.id}`);
                              dispatch(
                                customerActions.loadCustomers({
                                  page: 1,
                                  limit: 50,
                                })
                              );
                            } catch (error) {
                              alert(
                                'Se ha producido un error al remover el cliente'
                              );
                            }
                          }
                        }}
                      >
                        <img src={ICON_REMOVE} alt="Eliminar" width={14} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Modal
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <CustomerForm
            customer={customerToEdit}
            onCreated={() => {
              dispatch(customerActions.loadCustomers({ page: 1, limit: 50 }));
              setShowForm(false);
              setCustomerToEdit(null);
            }}
            onCancel={() => {
              setShowForm(false);
              setCustomerToEdit(null);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default RootAdmin;
